import styled from "styled-components";

export const Container = styled.div`
  min-height: 100vh;
  max-width: 100vw;

  overflow-x: hidden;

  display: flex;
  justify-content: center;
  align-items: flex-start;

  background-color: ${(props) => props.theme["background.800"]};

  @media screen and (min-width: 768px) {
    padding: 24px;
  }
`;

export const VacancyContainer = styled.div`
  flex: 1;
  max-width: 100%;
  min-height: 100%;
  height: auto;

  display: flex;
  flex-direction: column;

  padding: 24px;

  border: 1px solid rgba(0, 0, 0, 0.06);
  box-shadow: rgba(27, 31, 35, 0.04) 0px 1px 0px,
    rgba(255, 255, 255, 0.25) 0px 1px 0px inset;
  border-top: 2px solid ${(props) => props.theme.primary};

  .ant-tag {
    font-size: 14px;
  }

  .react-player {
    width: 100%;
    height: 300px;

    div {
      height: 300px !important;
    }
  }

  .btn-cta {
    margin: 24px 0px 0px;
  }

  @media screen and (min-width: 768px) {
    max-width: 900px;

    .react-player {
      height: 400px;

      div {
        height: 400px !important;
      }
    }
  }
`;

export const VacancyHeader = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  flex: 1;

  .ant-btn {
    margin-top: 24px;
  }

  @media screen and (min-width: 768px) {
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    .ant-btn {
      margin-top: 0;
    }
  }
`;

export const VacancyTitle = styled.div`
  > span {
    font-size: 1.75rem;
    color: var(--dark-gray);
    font-weight: 500;
  }

  > div {
    margin-top: 8px;
  }
`;

export const VacancyDescriptionHeader = styled.div`
  > span {
    font-size: 1.125rem;
    color: var(--light-gray2);
    font-weight: 500;
  }
`;

export const VacancyDescription = styled.div`
  display: flex;
  flex-direction: column;
  align-items: stretch;
  min-height: 0;
  background-color: var(--black-lighter);

  margin-top: 8px;

  p {
    color: var(--light-gray2);
    font-size: 18px;
  }

  strong,
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    font-weight: 500;
    color: var(--primary-shaded);
  }

  strong {
    font-size: 20px;
  }

  h1 {
    font-size: 26px;
  }

  h2 {
    font-size: 24px;
  }

  h3 {
    font-size: 24px;
  }

  h4 {
    font-size: 22px;
  }

  h5 {
    font-size: 22px;
  }

  h6 {
    font-size: 20px;
  }

  li {
    margin-left: 20px;
    color: unset;
  }
`;

export const WrapperRedesSociais = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  background: white;

  ul {
    display: flex;
    list-style: none;

    li {
      margin-left: 20px;
    }
  }
`;
