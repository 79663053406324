import React, { useCallback, useState, useEffect } from "react";

import {
  RouteProps as ReactDOMRouteProps,
  Route as ReactDOMRoute,
  Redirect,
  useRouteMatch,
} from "react-router-dom";

import api from "../services/api";

import { useAuth } from "../hooks/auth";

import Loading from "../components/Loading";

import MainLayout from "../layouts/MainLayout";

interface RouteProps extends ReactDOMRouteProps {
  isPrivate: boolean;
  component: React.ComponentType;
}

interface IAccount {
  emp_id: number;

  conta_id: number;

  conta_ativo: number;

  conta_url: string;
}

interface IMatchParams {
  account: string;
}

const Route: React.FC<RouteProps> = ({
  isPrivate = false,
  component: Component,
  ...rest
}) => {
  const { user } = useAuth();

  const match = useRouteMatch<IMatchParams>({
    path: "/:account",
    exact: false,
  });

  const account = match?.params.account;

  const [loading, setLoading] = useState(true);

  const [authenticated, setAuthenticated] = useState(false);

  const [accountInvalid, setAccountInvalid] = useState(false);

  const authenticate = useCallback(async () => {
    try {
      const response = await api.get<IAccount>(`accounts/${account}`);

      const accountData = response.data;

      if (
        isPrivate &&
        (!user ||
          user.emp_id !== accountData.emp_id ||
          user.conta_id !== accountData.conta_id)
      ) {
        setAuthenticated(false);
        return;
      }

      setAuthenticated(true);
    } catch {
      setAccountInvalid(true);
    } finally {
      setLoading(false);
    }
  }, [account, isPrivate, user]);

  useEffect(() => {
    authenticate();
  }, [authenticate]);

  return (
    <ReactDOMRoute
      {...rest}
      render={({ location }) => {
        return authenticated || !accountInvalid ? (
          (isPrivate && user) || !isPrivate ? (
            !isPrivate ? (
              <Component />
            ) : (
              <MainLayout component={Component} />
            )
          ) : (
            <Redirect
              to={{
                pathname: `/${account}/signin`,
                state: {
                  from: location,
                },
              }}
            />
          )
        ) : loading ? (
          <Loading />
        ) : (
          <Redirect
            to={{
              pathname: "/404",
              state: {
                from: location,
              },
            }}
          />
        );
      }}
    />
  );
};

export default Route;
