import styled from "styled-components";

interface LoadingContainerProps {
  coverScreen: boolean;
}

export const Container = styled.div<LoadingContainerProps>`
  height: ${(props) => (props.coverScreen ? "100vh" : "100%")};
  width: ${(props) => (props.coverScreen ? "100vw" : "100%")};
  display: flex;
  align-items: center;
  justify-content: center;
`;
