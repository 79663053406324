import React from "react";

import { Redirect, Route as DefaultRoute, Switch } from "react-router-dom";

import Route from "./Route";

import Home from "../pages/Home";
import SignIn from "../pages/SignIn";
import SignUp from "../pages/SignUp";
import ForgotPassword from "../pages/ForgotPassword";
import Profile from "../pages/Profile";
import NotFound from "../pages/NotFound";
import Vacancy from "../pages/Vacancy";
import Dashboard from "../pages/Dashboard";
import Password from "../pages/Password";

const Routes: React.FC = () => {
  return (
    <Switch>
      <DefaultRoute path="/404" exact component={NotFound} />
      <Route path="/:account" exact component={Home} isPrivate={false} />
      <Route
        path="/:account/signin"
        exact
        component={SignIn}
        isPrivate={false}
      />
      <Route
        path="/:account/signup"
        exact
        component={SignUp}
        isPrivate={false}
      />
      <Route
        path="/:account/forgot-password"
        exact
        component={ForgotPassword}
        isPrivate={false}
      />
      <Route path="/:account/dashboard" exact component={Dashboard} isPrivate />
      <Route path="/:account/profile" exact component={Profile} isPrivate />
      <Route path="/:account/password" exact component={Password} isPrivate />
      <Route
        path="/:account/vacancies/:vacancy_id"
        exact
        component={Vacancy}
        isPrivate={false}
      />
      <DefaultRoute path="/:account/*" component={NotFound} />
      <DefaultRoute
        path="*"
        // render={() => <Redirect to="/apollo" />}
        component={() => {
          window.location.href = "https://teamhub.com.br";
          return null;
        }}
      />
    </Switch>
  );
};

export default Routes;
