import React, { useState, useEffect, useCallback, useMemo } from "react";

import "moment/locale/pt-br";
import "moment-timezone";

import Moment from "react-moment";

import { Button, List } from "antd";

import Icon, { UserOutlined } from "@ant-design/icons";

import {
  FaDollarSign,
  FaBuilding,
  FaBriefcase,
  FaCalendarDay,
  FaChevronRight,
  FaChevronLeft,
  FaClock,
} from "react-icons/fa";

import {
  AiOutlineGlobal,
  AiOutlineInstagram,
  AiOutlineFacebook,
  AiOutlineLinkedin,
} from "react-icons/ai";

import DOMPurify from "dompurify";

import ReactPlayer from "react-player";

import { Link, useHistory, useParams } from "react-router-dom";

import { Link as ScrollLink, animateScroll, scroller } from "react-scroll";

// Components
import {
  Logo,
  SignInButton,
  Menu,
  Banner,
  Layout,
  HeaderColors,
  AboutUsContainer,
  AboutUs,
  JobsContainer,
  Jobs,
  JobsItem,
  JobTitle,
  JobDetails,
  JobsHeader,
  Carousel,
  JobWrapper,
  WrapperRedesSociais,
  VacancyDescription,
  ImagemBanner,
} from "./styles";

// Assets
import logoImg from "../../assets/logo.png";
import api from "../../services/api";
import { capitalize } from "../../utils/capitalize";
import { useTheme } from "../../hooks/theme";
import Loading from "../../components/Loading";
import defaultBackground from "../../assets/default_background.jpg";
import { ReactComponent as Svg } from "../../assets/onu_pcd.svg";

const { Header, Footer } = Layout;

interface Vacancy {
  emp_id: number;
  conta_id: number;
  processo_id: number;
  salario_faixa: string;
  homeoffice: boolean;
  fim_publicacao: Date;
  tipo: string;
  team: {
    grupo_nome: string;
  };
  position: {
    cargo_nome: string;
  };
  city: {
    CIDADE: string;
  };
  state: {
    UF: string;
  };
  contract?: {
    nome: string;
  };
  pcd?: number;
  pcd_tipo?: string;
}

interface VacancyParsed {
  emp_id: number;
  conta_id: number;
  processo_id: number;
  title: string;
  workplace?: string;
  contract?: string;
  pcd?: number;
  pcd_tipo?: string;
  homeoffice?: boolean;
  salary?: string;
  closingAt?: Date;
  workTime?: string;
}

const Home: React.FC = () => {
  const { loadingTheme, gallery, params } = useTheme();

  const { account } = useParams<{ account: string }>();

  const history = useHistory();

  const [selectedMenuItem, setSelectedMenuItem] = useState<string[]>(["1"]);

  const [headerColor, setHeaderColor] = useState<HeaderColors>("transparent");

  const [loadingVacancies, setLoadingVacancies] = useState(false);

  const [vacancies, setVacancies] = useState<Vacancy[]>([]);

  const loadVacancies = useCallback(async () => {
    setLoadingVacancies(true);
    try {
      const response = await api.get<Vacancy[]>(`${account}/vacancies`);
      if (response.status === 200) {
        setVacancies(response.data);
      }
    } finally {
      setLoadingVacancies(false);
    }
  }, [account]);

  useEffect(() => {
    loadVacancies();
  }, [loadVacancies]);

  const vacanciesParsed = useMemo(
    (): VacancyParsed[] =>
      vacancies.map((vacancy) => {
        const endDate = new Date(vacancy.fim_publicacao);

        let workplace;

        if (vacancy.city && vacancy.state) {
          workplace = `${vacancy.city.CIDADE} / ${vacancy.state.UF}`;
        } else if (vacancy.city) {
          workplace = vacancy.city.CIDADE;
        } else if (vacancy.state) {
          workplace = vacancy.state.UF;
        }

        return {
          ...vacancy,
          homeoffice: !!vacancy.homeoffice,
          salary: vacancy.salario_faixa,
          title: capitalize(vacancy.position.cargo_nome),
          closingAt: vacancy.fim_publicacao ? endDate : undefined,
          contract: vacancy.contract ? vacancy.contract.nome : undefined,
          workplace,
        };
      }),
    [vacancies]
  );

  const listenScrollEvent = (event: Event) => {
    if (
      (window.innerWidth < 768 && window.scrollY < 280) ||
      (window.innerWidth >= 768 && window.scrollY < 480)
    ) {
      setHeaderColor("primary");
    } else {
      setHeaderColor("primary");
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", listenScrollEvent);

    return () => window.removeEventListener("scroll", listenScrollEvent);
  }, []);

  const logoPhrase = useMemo(() => {
    if (!params.logo_phrase) return null;

    return params.logo_phrase;
  }, [params.logo_phrase]);

  const aboutUsTitle = useMemo(() => {
    if (!params.about_us_title) return "";

    return params.about_us_title;
  }, [params.about_us_title]);

  const aboutUsContent = useMemo(() => {
    if (!params.about_us_text) return "";

    return DOMPurify.sanitize(params.about_us_text, {
      ALLOWED_TAGS: [
        "h1",
        "h2",
        "h3",
        "h4",
        "h5",
        "h6",
        "p",
        "span",
        "strong",
        "em",
        "ul",
        "li",
        "ol",
      ],
    });
  }, [params.about_us_text]);

  const enterTeamTitle = useMemo(() => {
    if (!params.enter_team_title) return "Entre para nosso time ! ?";

    return params.enter_team_title;
  }, [params.enter_team_title]);

  const enterTeamContent = useMemo(() => {
    if (!params.enter_team_text) return "";

    return DOMPurify.sanitize(params.enter_team_text, {
      ALLOWED_TAGS: [
        "h1",
        "h2",
        "h3",
        "h4",
        "h5",
        "h6",
        "p",
        "span",
        "strong",
        "em",
        "ul",
        "li",
        "ol",
      ],
    });
  }, [params.enter_team_text]);

  const logo = useMemo(() => {
    return params && params.logo ? params.logo : logoImg;
  }, [params]);

  const background = useMemo(() => {
    return params && params.background_img
      ? params.background_img
      : defaultBackground;
  }, [params]);

  const site = useMemo(() => {
    const padraoLink = /^((http|https):\/\/)/;
    let novoLink = params.site;
    if (!padraoLink.test(params.site)) {
      novoLink = `https://${params.site}`;
    }
    return params && params.site ? (
      <a href={novoLink} target="_blank" rel="noreferrer">
        <AiOutlineGlobal size={36} />
      </a>
    ) : (
      ""
    );
  }, [params]);

  const instagram = useMemo(() => {
    const padraoLink = /^((http|https):\/\/)/;
    let novoLink = params.instagram;
    if (!padraoLink.test(params.instagram)) {
      novoLink = `https://${params.instagram}`;
    }
    return params && params.instagram ? (
      <a href={novoLink} target="_blank" rel="noreferrer">
        <AiOutlineInstagram size={36} />
      </a>
    ) : (
      ""
    );
  }, [params]);

  const facebook = useMemo(() => {
    const padraoLink = /^((http|https):\/\/)/;
    let novoLink = params.facebook;
    if (!padraoLink.test(params.facebook)) {
      novoLink = `https://${params.facebook}`;
    }
    return params && params.facebook ? (
      <a href={novoLink} target="_blank" rel="noreferrer">
        <AiOutlineFacebook size={36} />
      </a>
    ) : (
      ""
    );
  }, [params]);

  const linkedin = useMemo(() => {
    const padraoLink = /^((http|https):\/\/)/;
    let novoLink = params.linkedin;
    if (!padraoLink.test(params.linkedin)) {
      novoLink = `https://${params.linkedin}`;
    }
    return params && params.linkedin ? (
      <a href={novoLink} target="_blank" rel="noreferrer">
        <AiOutlineLinkedin size={36} />
      </a>
    ) : (
      ""
    );
  }, [params]);

  const scrollToTop = useCallback(() => {
    animateScroll.scrollToTop();
  }, []);

  const handleChangeSelectedMenuItem = useCallback((key: string) => {
    setSelectedMenuItem([key]);
  }, []);

  return loadingTheme ? (
    <Loading />
  ) : (
    <Layout headercolor={headerColor}>
      <Header style={{ background: "white" }}>
        <Logo
          style={{ paddingTop: 30 }}
          onClick={() => {
            scrollToTop();
          }}
        >
          <img src={logo} alt="Logo" />
        </Logo>
        <Menu
          style={{ marginTop: 15 }}
          theme="dark"
          mode="horizontal"
          multiple={false}
          selectedKeys={selectedMenuItem}
          onClick={(event) => {
            handleChangeSelectedMenuItem(String(event.key));
          }}
        >
          <Menu.Item
            key="1"
            onClick={() => {
              scrollToTop();
            }}
          >
            Home
          </Menu.Item>
          <Menu.Item key="2">
            <ScrollLink
              to="aboutUs"
              spy
              smooth
              offset={-70}
              duration={500}
              onClick={() => {
                handleChangeSelectedMenuItem("2");
              }}
            >
              Quem somos
            </ScrollLink>
          </Menu.Item>
          <Menu.Item key="3">
            <ScrollLink
              to="jobs"
              spy
              smooth
              offset={-70}
              duration={500}
              onClick={() => {
                handleChangeSelectedMenuItem("3");
              }}
            >
              Jobs
            </ScrollLink>
          </Menu.Item>
          <SignInButton key="4">
            <Link to={`/${account}/signin`}>
              <Button type="link" icon={<UserOutlined />}>
                Entrar
              </Button>
            </Link>
          </SignInButton>
        </Menu>
      </Header>
      <ImagemBanner src={background} alt="Logo" style={{ marginTop: 94 }} />
      {/* <Banner background={background}>
        <div className="banner-image" />
        <div className="banner-filter" />
         <div className="banner-text">
          <img src={logo} alt="Logo TeamHub" />
          {logoPhrase && <h2>{logoPhrase}</h2>}
        </div>
      </Banner> */}
      {aboutUsTitle ||
      aboutUsContent ||
      params.about_us_video ||
      gallery.length > 0 ? (
        <AboutUsContainer>
          <AboutUs id="aboutUs">
            {aboutUsTitle && (
              <div>
                <h1>{aboutUsTitle}</h1>
              </div>
            )}
            {aboutUsContent && (
              <VacancyDescription
                dangerouslySetInnerHTML={{ __html: aboutUsContent }}
              />
            )}
            {gallery.length > 0 || params.about_us_video ? (
              <Carousel
                arrows
                nextArrow={<FaChevronRight />}
                prevArrow={<FaChevronLeft />}
                autoplay={!params.about_us_video}
              >
                {params.about_us_video && (
                  <iframe
                    title="Vídeo"
                    width="1120"
                    height="500"
                    src={
                      params.about_us_video.includes("/watch?v=")
                        ? params.about_us_video.replace("/watch?v=", "/embed/")
                        : params.about_us_video
                    }
                    frameBorder="0"
                    allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                    allowFullScreen
                  />
                )}
                {gallery.map((image) => (
                  <div key={image.url}>
                    <img src={image.url} alt={image.filename} />
                  </div>
                ))}
              </Carousel>
            ) : null}
          </AboutUs>
        </AboutUsContainer>
      ) : null}

      <JobsContainer>
        <Jobs id="jobs">
          <JobsHeader>
            <div>
              <h1>{enterTeamTitle}</h1>
            </div>
            {enterTeamContent && (
              <VacancyDescription
                dangerouslySetInnerHTML={{ __html: enterTeamContent }}
              />
            )}
          </JobsHeader>
          {loadingVacancies ? (
            <Loading size={48} coverScreen={false} />
          ) : (
            <List
              dataSource={vacanciesParsed}
              loading={loadingVacancies}
              renderItem={(job, index) => {
                return (
                  <JobWrapper key={index}>
                    <JobsItem
                      onClick={() =>
                        history.push(`/${account}/vacancies/${job.processo_id}`)
                      }
                    >
                      <JobTitle>
                        <span>{job.title}</span>
                      </JobTitle>
                      <JobDetails>
                        {job.pcd === 1 && (
                          <div className="job-type">
                            <Icon
                              style={{ marginRight: 4 }}
                              component={() => <Svg height="21" width="21" />}
                            />
                            <span>
                              {`Vaga para PCD ${
                                job.pcd_tipo ? job.pcd_tipo : ""
                              }`}
                            </span>
                          </div>
                        )}
                        {job.contract && (
                          <div className="job-type">
                            <FaBriefcase size={18} />
                            <span>{job.contract}</span>
                          </div>
                        )}
                        {job.workplace && (
                          <div className="job-workplace">
                            <FaBuilding size={18} />
                            <span>
                              {job.homeoffice ? "Home-office" : job.workplace}
                            </span>
                          </div>
                        )}
                        {job.workTime && (
                          <div className="job-worktime">
                            <FaClock size={18} />
                            <span>{job.workTime}</span>
                          </div>
                        )}
                        {job.salary && (
                          <div className="job-salary">
                            <FaDollarSign size={18} />
                            <span>{job.salary}</span>
                          </div>
                        )}
                        {job.closingAt && (
                          <div className="job-deadline">
                            <FaCalendarDay size={18} />
                            <Moment
                              locale="pt-br"
                              format="[Fechando em] LL"
                              date={job.closingAt}
                            />
                          </div>
                        )}
                      </JobDetails>
                    </JobsItem>
                  </JobWrapper>
                );
              }}
            />
          )}
        </Jobs>
      </JobsContainer>

      <Footer>TeamHub ©{new Date().getFullYear()}</Footer>
      <WrapperRedesSociais>
        {site ? <Footer>{site}</Footer> : null}
        {instagram ? <Footer>{instagram}</Footer> : null}
        {facebook ? <Footer>{facebook}</Footer> : null}
        {linkedin ? <Footer>{linkedin}</Footer> : null}
      </WrapperRedesSociais>
    </Layout>
  );
};

export default Home;
