import styled, { css } from "styled-components";

import { Button } from "antd";

export const Container = styled.div`
  display: flex;

  justify-content: center;
  align-items: center;
`;

export const ProfileContainer = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 0;
  min-width: 0;
  max-width: 700px;
  width: 100%;
  align-items: center;

  > form {
    flex: 1;
    min-height: 0;
    max-width: 700px;
    width: 100%;
    display: flex;
    flex-direction: column;

    .ant-tabs {
      flex: 1;
      margin-bottom: 16px;
      width: 100%;
    }

    .ant-tabs-nav-list {
      div:nth-last-of-type(2) {
        margin: 0;
      }
    }

    .ant-tabs-content-holder {
      flex: 1;
      overflow-y: auto;

      .ant-tabs-content {
        flex: 1;
      }
    }
  }

  > .ant-btn {
    width: 100%;

    & + .ant-btn {
      margin-top: 16px;
    }
  }

  @media screen and (min-width: 768px) {
    justify-content: flex-start;
    > form {
      flex: none;
    }
  }
`;

export const AvatarContainer = styled.div`
  margin-bottom: 16px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

interface IFormFlexItemProps {
  width: number;
}

export const FormFlexItem = styled.div<IFormFlexItemProps>`
  ${(props) => css`
    width: ${props.width}%;
  `}

  .ant-picker {
    width: 100%;
  }
`;

export const FormFlexRow = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;

  > ${FormFlexItem} + ${FormFlexItem} {
    margin-left: 16px;
  }
`;

export const ButtonContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: end;
  width: 100%;
  margin: 16px 0px;

  > Button {
    margin-left: 1rem;
  }
`;

export const GoBack = styled(Button)`
  border: none;
  background-color: transparent;
  color: #1890ff;
  margin-bottom: 12px;
`;
