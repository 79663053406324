import { createGlobalStyle, css } from "styled-components";

import { lighten } from "polished";

const globalStyle = css`
  :root {
    --primary: ${(props) => props.theme.primary};
    --primary-shaded: black;
    --secondary: ${(props) => props.theme.secondary};
    --secondary-shaded: ${(props) => props.theme.secondaryShaded};
    --gray1: ${(props) => props.theme.gray1};
    --gray2: ${(props) => props.theme.gray2};
    --gray3: ${(props) => props.theme.gray3};
    --gray4: ${(props) => props.theme.gray4};
    --gray5: ${(props) => props.theme.gray5};
    --dark-gray: ${(props) => props.theme.darkGray};
    --font.800: ${(props) => props.theme["font.800"]};
    --font.600: ${(props) => props.theme["font.600"]};
    --font.400: ${(props) => props.theme["font.400"]};
    --font.200: ${(props) => props.theme["font.200"]};
    --font.100: ${(props) => props.theme["font.100"]};
    --background400: ${(props) => props.theme["background.400"]};
    --background600: ${(props) => props.theme["background.600"]};
    --background800: ${(props) => props.theme["background.800"]};
  }

  *,
  :after,
  :before {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    outline: 0;
  }

  body {
    background-color: var(--background800);
    color: var(--font.800);
    -webkit-font-smoothing: antialiased;
  }

  body,
  input,
  button {
    font-family: "Roboto", sans-serif;
    font-size: 16px;
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  strong {
    font-weight: 500;
  }

  button {
    cursor: pointer;
  }

  *::-webkit-scrollbar {
    width: 10px;
  }

  *::-webkit-scrollbar-track {
    background: #a6a6a6;
  }

  *::-webkit-scrollbar-thumb {
    background-color: #d6d6d6;
    border-radius: 5px;
  }
`;

export default createGlobalStyle`
    ${globalStyle}
`;

export const noPadding = { padding: "0px" };

export const noMargin = { margin: "0px" };
