import React from "react";

import { ConfigProvider } from "antd";

import pt_BR from "antd/es/locale-provider/pt_BR";

import { BrowserRouter } from "react-router-dom";

import { Provider } from "react-redux";

import store from "redux/store";

// React Toastify
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

// Styled Components css support
import { CSSProp } from "styled-components";
import Routes from "./routes";
import AppProvider from "./hooks";
import GlobalStyle from "./styles/global";

// React Toastify
import "react-toastify/dist/ReactToastify.css";

const App: React.FC = () => {
  return (
    <Provider store={store}>
      <BrowserRouter>
        <ConfigProvider locale={pt_BR}>
          <AppProvider>
            <Routes />
            <GlobalStyle />
          </AppProvider>
          <ToastContainer autoClose={3000} />
        </ConfigProvider>
      </BrowserRouter>
    </Provider>
  );
};

export default App;
