import React, { useCallback, useEffect, useMemo, useState } from "react";

import {
  Col,
  Row,
  Empty,
  Collapse,
  Tag,
  Button,
  Tooltip,
  Space,
  Alert,
  List,
} from "antd";

import { useParams, withRouter } from "react-router-dom";

import Icon, {
  ClockCircleOutlined,
  HomeOutlined,
  SolutionOutlined,
} from "@ant-design/icons";

import { toast } from "react-toastify";

import DOMPurify from "dompurify";

import { useDispatch, useSelector } from "react-redux";

import { fetchPesquisas } from "redux/modules/pesquisas/pesquisasSlice";

import { RootState } from "redux/rootReducer";

import { Pesquisa as PesquisaProps } from "ts/pesquisas/types";

import { noPadding, noMargin } from "styles/global";

import CustomCard from "components/CustomCard";
import { ReactComponent as Svg } from "../../assets/onu_pcd.svg";
import {
  Card,
  Vacancy,
  VacancyTitle,
  LinkVacancies,
  EvolutionTitle,
  Steps,
  Panel,
  ModalStyled,
  TextoTermoDescription,
  PesquisasPendentesRow,
  PesquisasConcluidasRow,
} from "./styles";
import { useAuth } from "../../hooks/auth";
import apiGc from "../../services/apiGc";
import api from "../../services/api";
import Pesquisa from "./components/Pesquisa";

import { useTheme } from "../../hooks/theme";

import logoImg from "../../assets/logo.png";

interface VacancyStepCandidate {
  etapa_id: number;
  pes_id: number;
  contratado: string;
  excluido: string;
}

interface VacancyStep {
  etapa_id: number;
  etapa: string;
  ordem: number;
  stepCandidates: VacancyStepCandidate[];
}

interface Quiz {
  quiz_id: number;
  titulo: string;
  instrucoes: string;
  objetivo: string;
  msg_encerramento: string;
}

interface VacancyQuiz {
  ativo: number;
  avalia_id: number;
  hashacesso: string;
  link_url: string;
  quiz: Quiz[];
}

interface Vacancy {
  emp_id: number;
  conta_id: number;
  processo_id: number;
  salario_faixa: string;
  homeoffice?: boolean;
  fim_publicacao: Date;
  pcd?: number;
  pcd_tipo?: string;
  team: {
    grupo_nome: string;
  };
  position: {
    cargo_nome: string;
  };
  city: {
    CIDADE: string;
  };
  state: {
    UF: string;
  };
  contract?: {
    nome: string;
  };
  steps: VacancyStep[];
  quizzes: VacancyQuiz[];
}

interface IProfile {
  pes_nome: string;
  pes_email: string;
  escolaridade: string;
  pes_cpf: string;
  pes_sexo: string;
  pes_datanascimento: Date;
  pes_cel: string;
  pes_ativo: number;
  pes_foto: string;
  pes_login: string;
  datacriacao: Date;
  dataadmissao: Date;
  datademissao: Date;
  profissao: string;
  prtsalario: number;
  indicacao: string;
  linkedin: string;
  twitter: string;
  site: string;
  curriculo: string;
  curriculum_url?: string;
  pcd_laudo: string;
  pcd_laudo_url?: string;
  cep: string;
  logradouro: string;
  nro: number;
  complemento: string;
  bairro: string;
  ufid: number;
  cidadeid: number;
  pcd: number;
  pcd_tipo: string;
  cid: string;
  termo_aceito: number;
  ufCidade: {
    UFID: number;
    CIDADE: string;
    CIDADEID: number;
  };
  account: {
    conta_razaonome: string;
    conta_url: string;
  };
}

interface ITermo {
  ativo: number;
  termo: string;
  updated_at: Date;
}

const Dashboard: React.FC = () => {
  const { user } = useAuth();

  const { account } = useParams<{ account: string }>();

  const { params } = useTheme();

  const [loadingVacancies, setLoadingVacancies] = useState(false);

  const [termo, setTermo] = useState<ITermo>();

  const [tituloTermo, setTituloTermo] = useState("TERMOS E CONDIÇÕES");
  const [termosCondicoes, setTermosCondicoes] = useState(false);
  const [aceitarTermo, setAceitarTermo] = useState(
    Number(localStorage.getItem("@JobsTeamhub:termo_aceito"))
  );

  const [vacancies, setVacancies] = useState<Vacancy[]>([]);

  const [profileInfo, setProfileInfo] = useState<IProfile>();

  const loadVacancies = useCallback(async () => {
    setLoadingVacancies(true);
    try {
      const response = await api.get(`${account}/dashboard`);

      if (response.status === 200) {
        setVacancies(response.data);
      }
    } finally {
      setLoadingVacancies(false);
    }
  }, [account]);

  const getProfileInfo = useCallback(async () => {
    setLoadingVacancies(true);
    try {
      const response = await api.get(`${user.account.conta_url}/profile`);

      if (response.status === 200) {
        setProfileInfo(response.data);
      }
    } finally {
      setLoadingVacancies(false);
    }
  }, [user.account.conta_url]);

  const loadVerificaoTermo = useCallback(async () => {
    try {
      const response = await apiGc.get(`/termo-e-condicoes/conta/${account}`);
      if (response.status === 200) {
        setTermo(response.data.termo);
      }
    } catch (err) {
      () => undefined;
    }

    setAceitarTermo(Number(localStorage.getItem("@JobsTeamhub:termo_aceito")));

    if (aceitarTermo === 0 && termo?.ativo === 1 && !termo?.updated_at) {
      setTermosCondicoes(true);
      setTituloTermo("TERMOS E CONDIÇÕES");
    } else if (aceitarTermo === 0 && termo?.ativo === 1 && termo?.updated_at) {
      setTermosCondicoes(true);
      setTituloTermo("ATUALIZAÇÃO DOS TERMOS E CONDIÇÕES");
    } else {
      setTermosCondicoes(false);
    }
  }, [account, termo?.ativo, aceitarTermo, termo?.updated_at]);

  const handleUpdateTermoPessoa = useCallback(async () => {
    try {
      const response = await api.put<IProfile>(
        `${user.account.conta_url}/profile`,
        {
          termo_aceito: 1,
        }
      );

      if (response.status === 200) {
        toast.success("Termo atualizado com sucesso!");
        setTermosCondicoes(false);
        localStorage.setItem("@JobsTeamhub:termo_aceito", "1");
      }
    } catch (err) {
      () => undefined;
    }
  }, [user.account.conta_url]);

  useEffect(() => {
    loadVacancies();
    getProfileInfo();
    if (!aceitarTermo) {
      loadVerificaoTermo();
    }
  }, [loadVacancies, loadVerificaoTermo, aceitarTermo, getProfileInfo]);

  const openVacancy = useCallback(
    (id: number) => {
      window.open(`/${account}/vacancies/${id}`, "_blank");
    },
    [account]
  );

  const appliedVacancies: Vacancy[] = useMemo(() => {
    const initialReduceState: VacancyStepCandidate[] = [];

    return vacancies.filter((vacancy) => {
      const candidates = vacancy.steps.reduce(
        (acc, next) => [
          ...acc,
          ...next.stepCandidates.map((candidate) => candidate),
        ],
        initialReduceState
      );

      const findCandidate = candidates.find(
        (candidate) => candidate.pes_id === user.pes_id
      );

      if (!findCandidate) return false;

      const excluido = !!Number(findCandidate.excluido);

      const contratado = !!Number(findCandidate.contratado);

      return findCandidate && !excluido && !contratado;
    });
  }, [user.pes_id, vacancies]);

  const availableVacancies: Vacancy[] = useMemo(() => {
    const initialReduceState: number[] = [];

    return vacancies.filter((vacancy) => {
      const candidates = vacancy.steps.reduce(
        (acc, next) => [
          ...acc,
          ...next.stepCandidates.map((candidate) => candidate.pes_id),
        ],
        initialReduceState
      );

      const findCandidate = candidates.find(
        (candidate) => candidate === user.pes_id
      );

      return !findCandidate;
    });
  }, [user.pes_id, vacancies]);

  const approvedVacancies: Vacancy[] = useMemo(() => {
    const initialReduceState: VacancyStepCandidate[] = [];

    return vacancies.filter((vacancy) => {
      const candidates = vacancy.steps.reduce(
        (acc, next) => [
          ...acc,
          ...next.stepCandidates.map((candidate) => candidate),
        ],
        initialReduceState
      );

      const findCandidate = candidates.find(
        (candidate) => candidate.pes_id === user.pes_id
      );

      if (!findCandidate) return false;

      const excluido = !!Number(findCandidate.excluido);

      const contratado = !!Number(findCandidate.contratado);

      return findCandidate && contratado && !excluido;
    });
  }, [user.pes_id, vacancies]);

  const disapprovedVacancies: Vacancy[] = useMemo(() => {
    const initialReduceState: VacancyStepCandidate[] = [];

    return vacancies.filter((vacancy) => {
      const candidates = vacancy.steps.reduce(
        (acc, next) => [
          ...acc,
          ...next.stepCandidates.map((candidate) => candidate),
        ],
        initialReduceState
      );

      const findCandidate = candidates.find(
        (candidate) => candidate.pes_id === user.pes_id
      );

      if (!findCandidate) return false;

      const excluido = !!Number(findCandidate.excluido);

      const contratado = !!Number(findCandidate.contratado);

      return findCandidate && !contratado && excluido;
    });
  }, [user.pes_id, vacancies]);

  const logo = useMemo(() => {
    return params && params.logo ? params.logo : logoImg;
  }, [params]);

  const { Step } = Steps;

  function getStepCandidate(value: any) {
    let etapaCandidato = 0;
    const stepCandidateValue = value.steps.map((step: any, index: number) =>
      step.stepCandidates.map((candidate: any) => {
        if (candidate.pes_id === user.pes_id) {
          etapaCandidato = index;
        }
        return index;
      })
    );
    return etapaCandidato;
  }

  function tags(vacancy: any) {
    if (!vacancy) {
      return [];
    }

    const tagsArray = [];

    if (vacancy.pcd === 1) {
      tagsArray.push({
        label: `Vaga para Pessoas Com Deficiência ${
          vacancy.pcd_tipo ? vacancy.pcd_tipo : ""
        } (PCD)`,
        icon: <Icon component={() => <Svg height="16" width="16" />} />,
      });
    }

    if (vacancy.homeoffice || vacancy.city || vacancy.state) {
      tagsArray.push({
        label: vacancy.homeoffice
          ? "HomeOffice"
          : vacancy.city && vacancy.state
          ? `${vacancy.city.CIDADE} / ${vacancy.state.UF}`
          : vacancy.city
          ? vacancy.city.CIDADE
          : vacancy.state?.UF,
        icon: <HomeOutlined />,
      });
    }

    if (vacancy.jornada) {
      tagsArray.push({
        label: vacancy.jornada,
        icon: <ClockCircleOutlined />,
      });
    }

    if (vacancy.contract) {
      tagsArray.push({
        label: vacancy.contract.nome,
        icon: <SolutionOutlined />,
      });
    }

    return tagsArray;
  }

  const purifiedTermoTexto = useMemo(() => {
    if (!termo?.termo) {
      return null;
    }

    return DOMPurify.sanitize(termo?.termo, {
      ALLOWED_TAGS: [
        "h1",
        "h2",
        "h3",
        "h4",
        "h5",
        "h6",
        "p",
        "span",
        "strong",
        "em",
        "ul",
        "li",
        "ol",
      ],
    });
  }, [termo?.termo]);

  /* function fieldsProfileInfo() {
    if (!profileInfo) {
      return null;
    }

    const fieldsArray = [];

    fieldsArray.push(
      `Sua ficha está incompleta. Por favor, vá até a aba do seu Perfil e preencha as informações: `
    );

    if (
      !profileInfo.pes_cpf ||
      !profileInfo.escolaridade ||
      !profileInfo.pes_datanascimento ||
      !profileInfo.pes_cel
    ) {
      fieldsArray.push(
        <li style={{ marginTop: "4px" }}>Informações Básicas</li>
      );
    }

    if (
      !profileInfo.cep ||
      !profileInfo.logradouro ||
      !profileInfo.bairro ||
      !profileInfo.nro ||
      !profileInfo.ufCidade.CIDADE
    ) {
      fieldsArray.push(<li style={{ marginTop: "4px" }}>Endereço</li>);
    }

    if (!profileInfo.profissao || !profileInfo.prtsalario) {
      fieldsArray.push(
        <li style={{ marginTop: "4px" }}>Informações Profissionais</li>
      );
    }

    return fieldsArray;
  } */

  const dispatch = useDispatch();

  const pesquisas = useSelector(
    (state: RootState) => state.pesquisas.pesquisas
  );

  const error = useSelector((state: RootState) => state.pesquisas.error);

  const [pesquisasPendentes, setPesquisasPendentes] = useState<
    Array<PesquisaProps>
  >([]);

  const [pesquisasConcluidas, setPesquisasCondluidas] = useState<
    Array<PesquisaProps>
  >([]);

  useEffect((): void => {
    dispatch(
      fetchPesquisas({
        pes_id: user.pes_id,
        conta_id: user.conta_id,
        emp_id: user.emp_id,
      })
    );
  }, [dispatch]);

  useEffect((): void => {
    if (error) {
      toast.error(error);
    }
  }, [error]);

  useEffect((): void => {
    if (pesquisas.length > 0) {
      setPesquisasPendentes(pesquisas.filter((p) => !p.data_entrega));
      setPesquisasCondluidas(pesquisas.filter((p) => p.data_entrega));
    }
  }, [pesquisas]);

  return (
    <Col span={24}>
      <Row gutter={[24, 24]}>
        <Col xs={24} sm={24} />
        <Col xs={24} sm={24}>
          <Card
            title="Seus processos seletivos"
            loading={loadingVacancies}
            bodyStyle={{ padding: "0" }}
            bordered={false}
          >
            {appliedVacancies.length === 0 ? (
              <Empty
                image={Empty.PRESENTED_IMAGE_SIMPLE}
                description={
                  <>
                    <span>
                      Você ainda não está cadastrado em nenhum processo
                      seletivo.
                    </span>
                    <p>Cheque as vagas disponíveis e candidate-se.</p>
                  </>
                }
              >
                <a href={`/${user.account.conta_url}/`}>
                  <Button type="primary">Ver vagas</Button>
                </a>
              </Empty>
            ) : (
              <Collapse
                bordered={false}
                ghost
                style={{
                  borderBottom: "1px solid #f0f0f0",
                }}
              >
                {appliedVacancies.map((vacancy) => (
                  <>
                    <Panel
                      header={
                        <VacancyTitle>
                          <Space>{vacancy.position.cargo_nome}</Space>
                        </VacancyTitle>
                      }
                      extra={
                        <LinkVacancies
                          onClick={() => openVacancy(vacancy.processo_id)}
                          key={vacancy.processo_id}
                        >
                          Ver vaga
                        </LinkVacancies>
                      }
                      key={vacancy.processo_id}
                      style={{
                        borderBottom: "1px solid #f0f0f0",
                        padding: "16px, 0, 16px, 0",
                      }}
                    >
                      <Row>
                        <Col md={4}>
                          <EvolutionTitle>Detalhes da vaga</EvolutionTitle>
                        </Col>
                        <Col md={20}>
                          <Row justify="end">
                            <div>
                              {tags(vacancy).map((tag, index) => (
                                <Tag key={index} icon={tag.icon}>
                                  {tag.label}
                                </Tag>
                              ))}
                            </div>
                          </Row>
                        </Col>
                      </Row>
                      <Row style={{ marginBottom: "10px" }}>
                        <Col md={24}>
                          <EvolutionTitle>Sua evolução</EvolutionTitle>
                          <Steps
                            size="small"
                            current={getStepCandidate(vacancy)}
                          >
                            {vacancy.steps.map((value) => (
                              <Step title={value.etapa} key={value.etapa_id} />
                            ))}
                          </Steps>
                        </Col>
                      </Row>
                      {vacancy.quizzes.length !== 0 && (
                        <Row>
                          <Col md={24}>
                            <EvolutionTitle>Testes</EvolutionTitle>
                            <List
                              itemLayout="horizontal"
                              dataSource={vacancy.quizzes}
                              renderItem={(item) => (
                                <List.Item>
                                  <List.Item.Meta
                                    title={
                                      <a
                                        href={`${item.link_url}/${account}/teste/${item.hashacesso}`}
                                      >
                                        {item.quiz[0].titulo}
                                      </a>
                                    }
                                  />
                                  <a
                                    href={`${item.link_url}/${account}/teste/${item.hashacesso}`}
                                  >
                                    <LinkVacancies>Ver teste</LinkVacancies>
                                  </a>
                                </List.Item>
                              )}
                            />
                          </Col>
                        </Row>
                      )}
                    </Panel>
                  </>
                ))}
              </Collapse>
            )}
          </Card>
        </Col>
        <Col xs={24} sm={24}>
          <Card
            title="Vagas disponíveis"
            loading={loadingVacancies}
            bodyStyle={{ padding: "0" }}
            bordered={false}
          >
            {availableVacancies.length === 0 ? (
              <Empty
                image={Empty.PRESENTED_IMAGE_SIMPLE}
                description={
                  <>
                    <p>Não há mais vagas disponíveis nesse momento.</p>
                  </>
                }
              />
            ) : (
              <Collapse
                bordered={false}
                ghost
                style={{
                  borderBottom: "1px solid #f0f0f0",
                }}
              >
                {availableVacancies.map((vacancy) => (
                  <Panel
                    header={
                      <VacancyTitle>{vacancy.position.cargo_nome}</VacancyTitle>
                    }
                    extra={
                      <LinkVacancies
                        onClick={() => openVacancy(vacancy.processo_id)}
                        key={vacancy.processo_id}
                      >
                        Ver vaga
                      </LinkVacancies>
                    }
                    key={vacancy.processo_id}
                    style={{
                      borderBottom: "1px solid #f0f0f0",
                      padding: "16px, 0, 16px, 0",
                    }}
                  >
                    <Row>
                      <Col md={12}>
                        <EvolutionTitle>Detalhes da vaga</EvolutionTitle>
                      </Col>
                      <Col md={12}>
                        <Row justify="end">
                          {tags(vacancy).map((tag, index) => (
                            <Tag key={index} icon={tag.icon}>
                              {tag.label}
                            </Tag>
                          ))}
                        </Row>
                      </Col>
                    </Row>
                  </Panel>
                ))}
              </Collapse>
            )}
          </Card>
        </Col>
        <Col xs={24} sm={24}>
          <Card
            title="Processos finalizados"
            loading={loadingVacancies}
            bodyStyle={{ padding: "0" }}
            bordered={false}
          >
            {approvedVacancies.length + disapprovedVacancies.length === 0 ? (
              <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
            ) : (
              <Collapse
                bordered={false}
                ghost
                style={{
                  borderBottom: "1px solid #f0f0f0",
                }}
              >
                {approvedVacancies.map((vacancy) => (
                  <Panel
                    header={
                      <VacancyTitle>{vacancy.position.cargo_nome}</VacancyTitle>
                    }
                    extra={
                      <LinkVacancies
                        onClick={() => openVacancy(vacancy.processo_id)}
                        key={vacancy.processo_id}
                      >
                        Ver vaga
                      </LinkVacancies>
                    }
                    key={vacancy.processo_id}
                    style={{
                      borderBottom: "1px solid #f0f0f0",
                      padding: "16px, 0, 16px, 0",
                    }}
                  >
                    <Row>
                      <Col md={12}>
                        <EvolutionTitle>Detalhes da vaga</EvolutionTitle>
                      </Col>
                      <Col md={12}>
                        <Row justify="end">
                          {tags(vacancy).map((tag, index) => (
                            <Tag key={index} icon={tag.icon}>
                              {tag.label}
                            </Tag>
                          ))}
                        </Row>
                      </Col>
                    </Row>
                  </Panel>
                ))}
                {disapprovedVacancies.map((vacancy) => (
                  <Panel
                    header={
                      <VacancyTitle>{vacancy.position.cargo_nome}</VacancyTitle>
                    }
                    extra={
                      <LinkVacancies
                        onClick={() => openVacancy(vacancy.processo_id)}
                        key={vacancy.processo_id}
                      >
                        Ver vaga
                      </LinkVacancies>
                    }
                    key={vacancy.processo_id}
                    style={{
                      borderBottom: "1px solid #f0f0f0",
                      padding: "16px, 0, 16px, 0",
                    }}
                  >
                    <Row>
                      <Col md={12}>
                        <EvolutionTitle>Detalhes da vaga</EvolutionTitle>
                      </Col>
                      <Col md={12}>
                        <Row justify="end">
                          {tags(vacancy).map((tag, index) => (
                            <Tag key={index} icon={tag.icon}>
                              {tag.label}
                            </Tag>
                          ))}
                        </Row>
                      </Col>
                    </Row>
                  </Panel>
                ))}
              </Collapse>
            )}
          </Card>
        </Col>
      </Row>
      <ModalStyled
        title={tituloTermo}
        centered
        visible={termosCondicoes}
        onCancel={handleUpdateTermoPessoa}
        cancelText="Aceito"
        closeIcon
        maskClosable={false}
        okText="Cancelar"
        onOk={() => setTermosCondicoes(false)}
        bodyStyle={{
          overflowY: "scroll",
        }}
        style={{
          height: "calc(100vh - 100px)",
          top: 30,
          textAlign: "center",
        }}
        width={600}
        okButtonProps={{
          type: "default",
        }}
        cancelButtonProps={{
          type: "primary",
        }}
      >
        <h4
          style={{
            textAlign: "justify",
          }}
        >
          {purifiedTermoTexto && (
            <>
              <TextoTermoDescription
                dangerouslySetInnerHTML={{ __html: purifiedTermoTexto }}
              />
            </>
          )}
        </h4>
      </ModalStyled>

      <Row gutter={16} style={noMargin}>
        <Col xs={24} sm={24} style={noPadding}>
          <CustomCard title="Pesquisas pendentes :" bordered={false}>
            <PesquisasPendentesRow>
              {pesquisasPendentes.map((p) => (
                <Pesquisa
                  tipo={p.tipo}
                  hash={p.hashacesso}
                  data_entrega={p.data_entrega}
                  data_solicitacao={p.data_solicitacao}
                  key={`${p.tipo}-${p.avalia_id}`}
                />
              ))}
            </PesquisasPendentesRow>
          </CustomCard>
        </Col>
        <Col xs={24} sm={24} style={noPadding}>
          <CustomCard title="Pesquisas concluídas :" bordered={false}>
            <PesquisasConcluidasRow>
              {pesquisasConcluidas.map((p) => (
                <Pesquisa
                  tipo={p.tipo}
                  hash={p.hashacesso}
                  data_entrega={p.data_entrega}
                  data_solicitacao={p.data_solicitacao}
                  key={`${p.tipo}-${p.avalia_id}`}
                />
              ))}
            </PesquisasConcluidasRow>
          </CustomCard>
        </Col>
      </Row>
    </Col>
  );
};

export default Dashboard;
