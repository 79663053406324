import React, { useCallback, useEffect, useMemo, useState } from "react";

import { Input, Button, Form, Alert, Space } from "antd";

import { Link, useHistory, useLocation, useParams } from "react-router-dom";

import { UserOutlined, LockOutlined } from "@ant-design/icons";
import { FiLogIn } from "react-icons/fi";

import { toast } from "react-toastify";
import { useAuth } from "../../hooks/auth";

import logoImg from "../../assets/logo.png";
import defaultBackground from "../../assets/default_background.jpg";

import { Container, Content, Background, AnimationContainer } from "./styles";
import api from "../../services/api";
import { useTheme } from "../../hooks/theme";

interface SignInFormData {
  email: string;
  password: string;
}

const SignIn: React.FC = () => {
  const { params } = useTheme();

  const history = useHistory();

  const location = useLocation<{ vacancy_id?: number }>();

  const { account } = useParams<{ account: string }>();

  const { signIn, user } = useAuth();

  const [fieldsToFill, setFieldsToFill] = useState(false);

  const handleSubmit = useCallback(
    async (data: SignInFormData) => {
      try {
        await signIn({
          account,
          email: data.email,
          password: data.password,
        });

        if (location && location.state) {
          if (location.state.vacancy_id) {
            try {
              window.open(
                `/${account}/vacancies/${location.state.vacancy_id}`,
                "_blank"
              );
            } catch (err) {
              toast.error(
                "Ocorreu um erro ao tentar se cadastrar em um processo seletivo!"
              );
            }
          }
        }

        // Profile required fields login checker
        await api
          .get(`${account}/dashboard/check-has-required-fields`)
          .then((response) => {
            if (response.status === 200) {
              setFieldsToFill(response.data);
            }
          });

        if (
          user &&
          user.account.conta_url === account &&
          fieldsToFill === false
        ) {
          history.push(`/${account}/dashboard`);
        } else if (user && user.account.conta_url === account && fieldsToFill) {
          history.push(`/${account}/profile`);
        }

        /* OLD - Profile Filled checker - v1
        const response = await api.get(`${account}/profile`);
        if (response.status === 200) {
          if (
            !response.data.pes_cpf ||
            !response.data.escolaridade ||
            !response.data.pes_cel ||
            !response.data.pes_datanascimento
          ) {
            history.push(`/${account}/profile`);
          } else history.push(`/${account}/dashboard`);
        }
        */
      } catch (err) {
        toast.error("Ocorreu um erro ao fazer login, cheque suas credenciais.");
      }
    },
    [account, fieldsToFill, history, location, signIn, user]
  );

  /* OLD - Fix bug: 'Mensagem de alerta na tela de login [sc-3118]'
  // Profile Filled checker - v2
  useEffect(() => {
    async function fetchData() {
      try {
        await api
          .get(`${account}/dashboard/check-has-required-fields`)
          .then((response) => {
            if (response.status === 200) {
              setFieldsToFill(response.data);
            }
          });
      }catch (err: any) {
      toast.error(err.data.message);
    }
    }
    fetchData();

    if (user && user.account.conta_url === account && fieldsToFill === false) {
      history.push(`/${account}/dashboard`);
    } else if (user && user.account.conta_url === account && fieldsToFill) {
      history.push(`/${account}/profile`);
    }
  }, [account, history, user, fieldsToFill]);
  */

  const logo = useMemo(() => {
    return params && params.logo ? params.logo : logoImg;
  }, [params]);

  const background = useMemo(() => {
    return params && params.background_img
      ? params.background_img
      : defaultBackground;
  }, [params]);

  return (
    <Container>
      <Content>
        <AnimationContainer>
          <Link
            to={{
              pathname: `/${account}/`,
            }}
          >
            <img style={{ maxHeight: 120 }} src={logo} alt="TeamHub" />
          </Link>
          <Form onFinish={handleSubmit} layout="vertical">
            <h1>Faça seu logon</h1>
            <Form.Item>
              <Alert
                message="Se você é um Colaborador, entre com as credencias fornecidas pela empresa."
                type="info"
              />
            </Form.Item>
            <Form.Item name="email">
              <Input
                prefix={<UserOutlined />}
                type="text"
                placeholder="E-mail"
                size="large"
              />
            </Form.Item>

            <Form.Item name="password">
              <Input
                prefix={<LockOutlined />}
                type="password"
                placeholder="Senha"
                size="large"
              />
            </Form.Item>

            <Button type="primary" ghost htmlType="submit" size="large">
              Entrar
            </Button>

            <Link to={`/${account}/forgot-password`}>Esqueci minha senha</Link>
          </Form>

          <Link
            to={{
              pathname: `/${account}/signup`,
              state:
                location && location.state
                  ? { vacancy_id: location.state.vacancy_id }
                  : {},
            }}
          >
            <FiLogIn />
            Criar conta
          </Link>
        </AnimationContainer>
      </Content>
      <Background background={background} />
    </Container>
  );
};

export default SignIn;
