import styled, { keyframes } from "styled-components";

import { shade } from "polished";

import signInBackgroundImg from "../../assets/default_background.jpg";

export const Container = styled.div`
  height: 100vh;
  display: flex;
  align-items: stretch;
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  width: 100%;
  max-width: 700px;
  padding: 0 20px;
`;

const appearFromLeft = keyframes`
  from {
    opacity: 0;
    transform: translateX(-50px);
  }
  to {
    opacity: 1;
    transform: translateX(0);
  }
`;

export const AnimationContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  max-width: 100%;

  animation: ${appearFromLeft} 1s;

  > img {
    max-width: 90%;
    max-height: 180px;
  }

  form {
    width: 100%;
    margin: 80px 0px;
    max-width: 340px;
    text-align: center;

    > .ant-form-item {
      max-width: 100%;

      .ant-input-prefix {
        margin-right: 11px;
      }
    }

    > button {
      width: 100%;
    }

    h1 {
      margin-bottom: 24px;
    }

    a {
      color: #f4ede8;
      display: block;
      margin-top: 24px;
      text-decoration: none;
      transition: color 0.2s;

      &:hover {
        color: ${shade(0.2, "#f4ede8")};
      }
    }
  }

  > a {
    color: var(--primary);
    margin-top: 24px;
    text-decoration: none;
    transition: color 0.2s;
    display: flex;
    align-items: center;

    &:hover {
      color: ${(props) => shade(0.2, props.theme.primary)};
    }

    svg {
      margin-right: 16px;
    }
  }
`;

interface BackgroundProps {
  background: any;
}

export const Background = styled.div<BackgroundProps>`
  flex: 1;
  background: url(${(props: any) => props.background}) no-repeat center;
  background-size: cover;
`;
