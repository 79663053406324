import axios from "axios";

import { toast } from "react-toastify";

const api = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
});

api.interceptors.request.use((request) => {
  const headers = {
    ...request.headers.common,
    ...(request.method ? { ...request.headers[request.method] } : {}),
    ...request.headers,
  };

  ["common", "get", "post", "head", "put", "patch", "delete"].forEach(
    (header) => {
      delete headers[header];
    }
  );

  return request;
});

api.interceptors.response.use(
  function (response) {
    const printable = `${new Date()} | Status: ${response.status} ${
      response.statusText
    } | URL: ${response.request.responseURL}\n`;

    return response;
  },
  function (error) {
    const { status, data } = error.response;

    if (status === 500) {
      toast.error("Houve um problema inesperado, tente novamente.");
    } else if (status !== 412 && data && data.message) {
      toast.error(data.message);
    } else if (status !== 412 && (data.errors || data.erros)) {
      const errors = data.errors || data.erros;

      errors.forEach((err: { message: string }) => {
        if (err.message) {
          toast.error(err.message);
        } else toast.error(err);
      });
    }

    return Promise.reject(error);
  }
);

export default api;
