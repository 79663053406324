import styled from "styled-components";

import {
  Card as AntdCard,
  Steps as AntdSteps,
  Collapse as AntdCollapse,
  Modal,
} from "antd";

import { Wrapper as PesquisaWrapper } from "./components/Pesquisa/style";

export const VacancyContainer = styled.div`
  padding: 6px;
`;

interface VacancyProps {
  status?: "progress" | "success" | "error";
}

export const Card = styled(AntdCard)`
  box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px,
    rgba(60, 64, 67, 0.15) 0px 1px 3px 1px;
`;

export const Vacancy = styled.div<VacancyProps>`
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;

  .anticon {
    position: absolute;
    z-index: 2;
    font-size: 24px;
    right: -6px;
    top: -6px;
    transition: color linear 0.2s;
    color: ${(props) =>
      props.status
        ? props.status === "success"
          ? "green"
          : props.status === "error"
          ? "red"
          : props.theme["font.200"]
        : props.theme.primary};
  }

  height: 200px;
  border-radius: 10px;
  background-color: ${(props) => props.theme["background.800"]};
  border: 2px solid ${(props) => props.theme["background.400"]};
  transition: border-color linear 0.2s;

  &:hover {
    cursor: pointer;
    border-color: ${(props) =>
      props.status
        ? props.status === "success"
          ? "green"
          : props.status === "error"
          ? "red"
          : props.theme["font.200"]
        : props.theme.primary};
    .anticon {
      color: ${(props) =>
        props.status
          ? props.status === "success"
            ? "green"
            : props.status === "error"
            ? "red"
            : props.theme["font.200"]
          : props.theme.primary};
    }
  }
`;

export const VacanciesContainer = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;

  > ${VacancyContainer} {
    width: 100%;
  }

  @media screen and (min-width: 576px) {
    > ${VacancyContainer} {
      width: 50%;
    }
  }

  @media screen and (min-width: 768px) {
    > ${VacancyContainer} {
      width: 33%;
    }
  }

  @media screen and (min-width: 992px) {
    > ${VacancyContainer} {
      width: 25%;
    }
  }

  @media screen and (min-width: 1200px) {
    > ${VacancyContainer} {
      width: 20%;
    }
  }

  @media screen and (min-width: 1600px) {
    > ${VacancyContainer} {
      width: 15%;
    }
  }
`;

export const VacancyImage = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  flex: 1;
  > img {
    width: 90%;
    margin: auto;
  }
`;

export const VacancyTitle = styled.span`
  font-weight: 500;
`;

export const EvolutionTitle = styled.span`
  display: flex;
  font-size: 14px;
  font-weight: 500;
  margin-bottom: 10px;
`;

export const LinkVacancies = styled.span`
  font-size: 14px;
  font-weight: 500;
  color: #30318b;
  &:hover {
    text-decoration: underline;
    cursor: pointer;
  }
`;

export const Steps = styled(AntdSteps)`
  padding: 0px;
`;

export const Panel = styled(AntdCollapse.Panel)`
  padding: 6px 14px;
`;

export const ModalStyled = styled(Modal)`
  .ant-modal-content {
    height: 100%;
    display: flex;
    flex-direction: column;
  }
`;

export const TextoTermoDescription = styled.div`
  display: flex;
  flex-direction: column;
  align-items: stretch;
  min-height: 0;
  background-color: var(--black-lighter);

  margin-top: 8px;

  p {
    color: var(--light-gray2);
    font-size: 18px;
  }

  strong,
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    font-weight: 500;
    color: var(--primary-shaded);
  }

  strong {
    font-size: 20px;
  }

  h1 {
    font-size: 26px;
  }

  h2 {
    font-size: 24px;
  }

  h3 {
    font-size: 24px;
  }

  h4 {
    font-size: 22px;
  }

  h5 {
    font-size: 22px;
  }

  h6 {
    font-size: 20px;
  }

  li {
    margin-left: 20px;
    color: unset;
  }
`;

export const Container = styled.div`
  > .ant-col > .ant-row {
    .ant-col + .ant-col {
      margin-top: 32px;
    }
  }
`;

const PesquisaRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;

  ${PesquisaWrapper} {
    width: 100%;
    min-width: 100%;
  }

  @media (min-width: 768px) {
    ${PesquisaWrapper} {
      width: 33%;
      min-width: 33%;
    }
  }

  @media (min-width: 1200px) {
    ${PesquisaWrapper} {
      width: 25%;
      min-width: 25%;
    }
  }

  @media (min-width: 2200px) {
    ${PesquisaWrapper} {
      width: 12.5%;
      min-width: 12.5%;
    }
  }
`;

export const PesquisasPendentesRow = styled(PesquisaRow)`
  flex-wrap: wrap;
  width: 100%;
`;

export const PesquisasConcluidasRow = styled(PesquisaRow)`
  flex-wrap: wrap;
  overflow-x: hidden;

  @media (min-width: 768px) {
    flex-wrap: nowrap;
    overflow-x: auto;
  }
`;
