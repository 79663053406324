import React, { createContext, useCallback, useState, useContext } from "react";
import { useHistory } from "react-router";
import { useRouteMatch } from "react-router-dom";
import { toast } from "react-toastify";

import api from "../services/api";

import { login, logout } from "../services/auth";

interface User {
  emp_id: number;
  conta_id: number;
  pes_id: number;
  pes_nome: string;
  pes_email: string;
  pes_foto: string;
  pcd: number;
  pcd_tipo: string;
  termo_aceito: number;
  account: {
    conta_razaonome: string;
    conta_url: string;
  };
}

interface AuthState {
  token: string;
  user: User;
}

interface SignInCredentials {
  email: string;
  password: string;
  account: string;
}

interface AuthContextData {
  user: User;
  signIn(credentials: SignInCredentials): Promise<void>;
  signOut(): void;
  updateUser(data: User): void;
}

interface IMatchParams {
  account: string;
}

const AuthContext = createContext<AuthContextData>({} as AuthContextData);

export const AuthProvider: React.FC = ({ children }) => {
  const history = useHistory();

  const match = useRouteMatch<IMatchParams>({
    path: "/:account",
    exact: false,
  });

  const [data, setData] = useState<AuthState>(() => {
    const token = localStorage.getItem("@JobsTeamhub:token");
    const user = localStorage.getItem("@JobsTeamhub:user");

    if (token && user) {
      api.defaults.headers.authorization = `Bearer ${token}`;
      return { token, user: JSON.parse(user) };
    }

    return {} as AuthState;
  });

  const signIn = useCallback(async ({ account, email, password }) => {
    const response = await api.post(`${account}/auth`, { email, password });

    const { token, user } = response.data;

    login(token, true);

    localStorage.setItem("@JobsTeamhub:token", token);
    localStorage.setItem("@JobsTeamhub:user", JSON.stringify(user));
    localStorage.setItem("@JobsTeamhub:termo_aceito", user.termo_aceito);

    api.defaults.headers.authorization = `Bearer ${token}`;

    setData({
      token,
      user,
    });
  }, []);

  const signOut = useCallback(() => {
    localStorage.removeItem("@JobsTeamhub:token");
    localStorage.removeItem("@JobsTeamhub:user");
    logout();
    setData({} as AuthState);
  }, []);

  const updateUser = useCallback(
    (user: User) => {
      setData({
        token: data.token,
        user,
      });

      localStorage.setItem("@JobsTeamhub:user", JSON.stringify(user));
    },
    [data.token]
  );

  api.interceptors.response.use(
    (response) => response,
    (error) => {
      if (error.response.status === 401) {
        toast.error("User is not a candidate.");
        signOut();
        if (match?.params && match.params.account) {
          history.push(`/${match.params.account}/signin`);
        }
      }

      return Promise.reject(error);
    }
  );

  return (
    <AuthContext.Provider
      value={{ user: data.user, signIn, signOut, updateUser }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = (): AuthContextData => {
  const context = useContext(AuthContext);

  return context;
};
