import React, { useCallback, useMemo } from "react";

import { Input, Button, Form } from "antd";

import { Link, useHistory, useParams } from "react-router-dom";

import { MailOutlined } from "@ant-design/icons";
import { FiArrowLeft } from "react-icons/fi";

import { toast } from "react-toastify";

import logoImg from "../../assets/logo.png";
import defaultBackground from "../../assets/default_background.jpg";

import { Container, Content, Background, AnimationContainer } from "./styles";
import apiGc from "../../services/apiGc";
import { useTheme } from "../../hooks/theme";

interface ForgotPasswordFormData {
  email: string;
}

const ForgotPassword: React.FC = () => {
  const { params } = useTheme();

  const history = useHistory();

  const { account } = useParams<{ account: string }>();

  const handleSubmit = useCallback(
    async (data: ForgotPasswordFormData) => {
      try {
        const enviarEmail = await apiGc.post(
          `/candidate/recovery/${account}`,
          data
        );
        toast.success(enviarEmail.data.message);

        history.push(`/${account}/dashboard`);
      } catch (err) {
        toast.error(
          "Ocorreu um erro ao tentar recuperar a senha, tente novamente."
        );
      }
    },
    [account, history]
  );

  const logo = useMemo(() => {
    return params && params.logo ? params.logo : logoImg;
  }, [params]);

  const background = useMemo(() => {
    return params && params.background_img
      ? params.background_img
      : defaultBackground;
  }, [params]);

  return (
    <Container>
      <Content>
        <AnimationContainer>
          <Link
            to={{
              pathname: `/${account}/`,
            }}
          >
            <img style={{ maxHeight: 120 }} src={logo} alt="Logo" />
          </Link>
          <Form onFinish={handleSubmit} layout="vertical">
            <h1>Esqueci minha senha</h1>

            <Form.Item
              name="email"
              rules={[
                { required: true, message: "Por favor, preencha seu e-mail" },
              ]}
            >
              <Input
                prefix={<MailOutlined />}
                type="email"
                placeholder="E-mail"
                size="large"
              />
            </Form.Item>

            <Button type="primary" ghost htmlType="submit" size="large">
              Enviar Email
            </Button>
          </Form>

          <Link to={`/${account}/signin`}>
            <FiArrowLeft />
            Voltar para logon
          </Link>
        </AnimationContainer>
      </Content>
      <Background background={background} />
    </Container>
  );
};

export default ForgotPassword;
