import React, { useCallback, useEffect, useMemo, useState } from "react";

import { Redirect, useHistory, useParams, Link } from "react-router-dom";

import ReactPlayer from "react-player";

import DOMPurify from "dompurify";

import { Button, Divider, Tag, Col, Row, Modal, Steps, List } from "antd";

import Icon, {
  CheckOutlined,
  ClockCircleOutlined,
  HomeOutlined,
  SolutionOutlined,
  UserAddOutlined,
  LeftOutlined,
} from "@ant-design/icons";

import {
  AiOutlineGlobal,
  AiOutlineInstagram,
  AiOutlineFacebook,
  AiOutlineLinkedin,
} from "react-icons/ai";

import { toast } from "react-toastify";
import { ReactComponent as Svg } from "../../assets/onu_pcd.svg";
import { useAuth } from "../../hooks/auth";

import api from "../../services/api";

// Components
import Loading from "../../components/Loading";

import {
  Container,
  VacancyContainer,
  VacancyTitle,
  VacancyDescriptionHeader,
  VacancyDescription,
  VacancyHeader,
  WrapperRedesSociais,
} from "./styles";

// Assets
import logoImg from "../../assets/logo.png";

import { useTheme } from "../../hooks/theme";

const { Step } = Steps;

// Interfaces
interface VacancyStepCandidate {
  pes_id: number;
}

interface VacancyStep {
  etapa_id: number;
  etapa: string;
  ordem: number;
  stepCandidates: VacancyStepCandidate[];
}

interface Quiz {
  quiz_id: number;
  titulo: string;
  instrucoes: string;
  objetivo: string;
  msg_encerramento: string;
}

interface VacancyQuiz {
  ativo: number;
  avalia_id: number;
  hashacesso: string;
  quiz: Quiz[];
  quiz_id: number;
}

interface VacancyInfo {
  emp_id: number;
  conta_id: number;
  processo_id: number;
  grupo_id: number;
  cargo_id: number;
  descricao: string;
  salario_faixa?: string;
  homeoffice?: boolean;
  fim_publicacao: Date;
  video?: string;
  jornada?: string;
  pcd?: number;
  pcd_tipo?: string;
  team: {
    grupo_nome: string;
  };
  position: {
    cargo_nome: string;
  };
  city?: {
    CIDADE: string;
  };
  state?: {
    UF: string;
  };
  contract?: {
    nome: string;
  };
  steps: VacancyStep[];
  quizzes: VacancyQuiz[];
}

interface ListQuiz {
  processo_id: number;
  quiz_id: number;
}

interface GetVacancyInfo {
  showLoadingIndicator?: boolean;
}

const Vacancy: React.FC = () => {
  const { user } = useAuth();

  const { params } = useTheme();

  const history = useHistory();

  const [loading, setLoading] = useState(true);

  const [vacancy, setVacancy] = useState<VacancyInfo>();

  const [applying, setApplying] = useState(false);

  const [confirmationVisible, setConfirmationVisible] = useState(false);

  const [confirmationNotVisible, setConfirmationNotVisible] = useState(false);

  const [listQuiz, setListQuiz] = useState<ListQuiz[]>();

  const [fieldsToFill, setFieldsToFill] = useState(false);

  const { account, vacancy_id } = useParams<{
    account: string;
    vacancy_id: string;
  }>();

  const getVerificationFieldsToFill = useCallback(async () => {
    if (user) {
      try {
        await api
          .get(`${account}/dashboard/check-has-required-fields`)
          .then((response) => {
            if (response.status === 200) {
              setFieldsToFill(response.data);
            }
          });
      } catch (err: any) {
        toast.error(err.data.message);
      }
    }
  }, [account, user]);

  const getListQuiz = useCallback(async () => {
    if (user) {
      try {
        const response = await api.get(`/list-psquiz/${vacancy_id}`);

        if (response?.status === 200) {
          setListQuiz(response.data);
        }
      } catch (err: any) {
        toast.error(err.data.message);
      }
    }
  }, [user, vacancy_id]);

  const getVacancyInfo = useCallback(
    async (
      { showLoadingIndicator }: GetVacancyInfo = { showLoadingIndicator: true }
    ) => {
      if (showLoadingIndicator) {
        setLoading(true);
      }
      try {
        let response = null;
        if (user) {
          response = await api.get(`${account}/vacancies/${vacancy_id}`);
        } else {
          response = await api.get(`${account}/vacancies/public/${vacancy_id}`);
        }

        if (response?.status === 200) {
          setVacancy(response.data);
        }
      } finally {
        setLoading(false);
      }
    },
    [account, vacancy_id, user]
  );

  useEffect(() => {
    if (vacancy_id) {
      getVacancyInfo();
      getListQuiz();
      getVerificationFieldsToFill();
    }
  }, [getVacancyInfo, getListQuiz, getVerificationFieldsToFill, vacancy_id]);

  const purifiedDescription = useMemo(() => {
    if (!vacancy || !vacancy.descricao) {
      return null;
    }

    return DOMPurify.sanitize(vacancy.descricao, {
      ALLOWED_TAGS: [
        "h1",
        "h2",
        "h3",
        "h4",
        "h5",
        "h6",
        "p",
        "span",
        "strong",
        "em",
        "ul",
        "li",
        "ol",
      ],
    });
  }, [vacancy]);

  function getStepCandidate(value: any) {
    let etapaCandidato = 0;
    const stepCandidateValue = value.steps.map((step: any, index: number) =>
      step.stepCandidates.map((candidate: any) => {
        if (candidate.pes_id === user.pes_id) {
          etapaCandidato = index;
        }
        return index;
      })
    );
    return etapaCandidato;
  }

  const tags = useMemo(() => {
    if (!vacancy) {
      return [];
    }

    const tagsArray = [];

    if (vacancy.pcd === 1) {
      tagsArray.push({
        label: `Vaga para Pessoas Com Deficiência ${
          vacancy.pcd_tipo ? vacancy.pcd_tipo : ""
        } (PCD)`,
        icon: <Icon component={() => <Svg height="16" width="16" />} />,
      });
    }

    if (vacancy.homeoffice || vacancy.city || vacancy.state) {
      tagsArray.push({
        label: vacancy.homeoffice
          ? "HomeOffice"
          : vacancy.city && vacancy.state
          ? `${vacancy.city.CIDADE} / ${vacancy.state.UF}`
          : vacancy.city
          ? vacancy.city.CIDADE
          : vacancy.state?.UF,
        icon: <HomeOutlined />,
      });
    }

    if (vacancy.jornada) {
      tagsArray.push({ label: vacancy.jornada, icon: <ClockCircleOutlined /> });
    }

    if (vacancy.contract) {
      tagsArray.push({
        label: vacancy.contract.nome,
        icon: <SolutionOutlined />,
      });
    }

    return tagsArray;
  }, [vacancy]);

  const handleApply = useCallback(async () => {
    if (!user) {
      history.push(`/${account}/signin`, { vacancy_id });
      return;
    }

    if (fieldsToFill) {
      toast.error("Preencha o seu perfil1");
    } else if (user.pcd === 0 && vacancy?.pcd === 1) {
      toast.error("Você não pode aplicar para uma vaga exclusivamente PCD!");
    } else {
      setApplying(true);
      try {
        await api.post(`/${account}/vacancies/${vacancy_id}/screen-candidate`);

        if (listQuiz) {
          listQuiz.map(async (quiz) => {
            await api.post(
              `quiz_avaliacao/${account}/${user.pes_id}/${quiz.quiz_id}/${vacancy_id}/${user.pes_email}`
            );
          });
        }

        toast.success("Aplicado com sucesso!");

        getVacancyInfo({ showLoadingIndicator: false });
      } finally {
        setApplying(false);
        setConfirmationVisible(false);
        setConfirmationNotVisible(false);

        setTimeout(async () => {
          await api.post(
            `/${account}/email-confirmation/${vacancy_id}/sendemail`
          );
        }, 6000);
      }
    }
  }, [
    account,
    getVacancyInfo,
    history,
    user,
    vacancy_id,
    vacancy?.pcd,
    listQuiz,
    fieldsToFill,
  ]);

  const handleConfirmation = useCallback(async () => {
    setConfirmationVisible(true);
  }, []);

  const handleConfirmationNotPCD = useCallback(async () => {
    setConfirmationNotVisible(true);
  }, []);

  const alreadyApplied = useMemo(() => {
    if (!user) {
      return false;
    }

    if (!vacancy) {
      return false;
    }

    const initialReduceState: number[] = [];

    const candidates = vacancy.steps.reduce(
      (acc, next) => [
        ...acc,
        ...next.stepCandidates.map((candidate) => candidate.pes_id),
      ],
      initialReduceState
    );

    return !!candidates.find((candidate) => candidate === user.pes_id);
  }, [user, vacancy]);

  const site = useMemo(() => {
    const padraoLink = /^((http|https):\/\/)/;
    let novoLink = params.site;
    if (!padraoLink.test(params.site)) {
      novoLink = `https://${params.site}`;
    }
    return params && params.site ? (
      <a href={novoLink} target="_blank" rel="noreferrer">
        <AiOutlineGlobal size={36} />
      </a>
    ) : (
      ""
    );
  }, [params]);

  const instagram = useMemo(() => {
    const padraoLink = /^((http|https):\/\/)/;
    let novoLink = params.instagram;
    if (!padraoLink.test(params.instagram)) {
      novoLink = `https://${params.instagram}`;
    }
    return params && params.instagram ? (
      <a href={novoLink} target="_blank" rel="noreferrer">
        <AiOutlineInstagram size={36} />
      </a>
    ) : (
      ""
    );
  }, [params]);

  const facebook = useMemo(() => {
    const padraoLink = /^((http|https):\/\/)/;
    let novoLink = params.facebook;
    if (!padraoLink.test(params.facebook)) {
      novoLink = `https://${params.facebook}`;
    }
    return params && params.facebook ? (
      <a href={novoLink} target="_blank" rel="noreferrer">
        <AiOutlineFacebook size={36} />
      </a>
    ) : (
      ""
    );
  }, [params]);

  const linkedin = useMemo(() => {
    const padraoLink = /^((http|https):\/\/)/;
    let novoLink = params.linkedin;
    if (!padraoLink.test(params.linkedin)) {
      novoLink = `https://${params.linkedin}`;
    }
    return params && params.linkedin ? (
      <a href={novoLink} target="_blank" rel="noreferrer">
        <AiOutlineLinkedin size={36} />
      </a>
    ) : (
      ""
    );
  }, [params]);

  const logo = useMemo(() => {
    return params && params.logo ? params.logo : logoImg;
  }, [params]);

  console.log(alreadyApplied);
  console.log("fieldsToFill");
  console.log(fieldsToFill);

  return loading ? (
    <Loading />
  ) : vacancy ? (
    <>
      <Col
        style={{
          display: "flex",
          justifyContent: "center",
          marginTop: "24px",
        }}
      >
        <Link
          to={
            !user
              ? { pathname: `/${account}/` }
              : {
                  pathname: `/${account}/dashboard`,
                }
          }
        >
          <img style={{ maxHeight: 120 }} src={logo} alt="Logo" />
        </Link>
      </Col>
      <Container>
        <VacancyContainer>
          <VacancyHeader>
            <VacancyTitle>
              <span>{vacancy.position.cargo_nome}</span>
              <div>
                {tags.map((tag, index) => (
                  <Tag key={index} icon={tag.icon}>
                    {tag.label}
                  </Tag>
                ))}
              </div>
            </VacancyTitle>
            <Button
              type="primary"
              icon={alreadyApplied ? <CheckOutlined /> : <UserAddOutlined />}
              size="large"
              onClick={(item) => {
                if (fieldsToFill) {
                  return history.push(`/${account}/profile`);
                }
                return user?.pcd === 1 &&
                  vacancy?.pcd === 1 &&
                  user?.pcd_tipo !== vacancy?.pcd_tipo
                  ? handleConfirmation()
                  : user?.pcd === 1 && vacancy?.pcd === 0
                  ? handleConfirmationNotPCD()
                  : handleApply();
              }}
              disabled={alreadyApplied}
              loading={applying}
            >
              {!fieldsToFill
                ? !alreadyApplied
                  ? "Candidatar-se"
                  : "Já candidatado"
                : "Preencha o seu perfil!"}
            </Button>
          </VacancyHeader>
          <Divider />
          {vacancy.video && (
            <div className="react-player">
              <ReactPlayer url={vacancy.video} muted controls width="100%" />
            </div>
          )}
          {vacancy.video && purifiedDescription && <Divider />}
          {purifiedDescription && (
            <>
              <VacancyDescriptionHeader>
                <span>Descrição da vaga</span>
              </VacancyDescriptionHeader>
              <VacancyDescription
                dangerouslySetInnerHTML={{ __html: purifiedDescription }}
              />
            </>
          )}
          {!!alreadyApplied && (
            <>
              <Divider />
              <Row style={{ marginBottom: "10px" }}>
                <Col md={24}>
                  <VacancyDescriptionHeader style={{ marginBottom: "10px" }}>
                    <span>Sua evolução</span>
                  </VacancyDescriptionHeader>
                  <Steps size="small" current={getStepCandidate(vacancy)}>
                    {vacancy.steps.map((value) => (
                      <Step title={value.etapa} key={value.etapa_id} />
                    ))}
                  </Steps>
                </Col>
              </Row>
              {vacancy.quizzes.length !== 0 && (
                <>
                  <Divider />
                  <Row>
                    <Col md={24}>
                      <VacancyDescriptionHeader>
                        <span>Testes</span>
                      </VacancyDescriptionHeader>
                      <List
                        itemLayout="horizontal"
                        dataSource={vacancy.quizzes}
                        renderItem={(item) => (
                          <List.Item>
                            <List.Item.Meta
                              title={
                                <a
                                  href={`https://app.gestaodecultura.com/${account}/teste/${item.hashacesso}`}
                                >
                                  {item.quiz[0].titulo}
                                </a>
                              }
                            />
                            <a
                              href={`https://app.gestaodecultura.com/${account}/teste/${item.hashacesso}`}
                            >
                              Ver teste
                            </a>
                          </List.Item>
                        )}
                      />
                    </Col>
                  </Row>
                </>
              )}
              <Divider />
            </>
          )}
          <WrapperRedesSociais>
            <ul>
              {site ? (
                <li>
                  <div>{site}</div>
                </li>
              ) : null}

              {instagram ? (
                <li>
                  <div>{instagram}</div>
                </li>
              ) : null}

              {facebook ? (
                <li>
                  <div>{facebook}</div>
                </li>
              ) : null}

              {linkedin ? (
                <li>
                  <div>{linkedin}</div>
                </li>
              ) : null}
            </ul>
          </WrapperRedesSociais>
          <Row justify="space-between">
            <Link
              to={
                !user
                  ? { pathname: `/${account}/` }
                  : {
                      pathname: `/${account}/dashboard`,
                    }
              }
            >
              <Button icon={<LeftOutlined />} size="large">
                Voltar a página anterior
              </Button>
            </Link>
            <Button
              type="primary"
              icon={alreadyApplied ? <CheckOutlined /> : <UserAddOutlined />}
              size="large"
              onClick={(item) => {
                if (fieldsToFill) {
                  return history.push(`/${account}/profile`);
                }
                return user?.pcd === 1 && user?.pcd_tipo !== vacancy?.pcd_tipo
                  ? handleConfirmation()
                  : handleApply();
              }}
              disabled={alreadyApplied}
              loading={applying}
            >
              {!fieldsToFill
                ? !alreadyApplied
                  ? "Candidatar-se"
                  : "Já candidatado"
                : "Preecha o seu perfil!"}
            </Button>
          </Row>
        </VacancyContainer>
        <Modal
          title="Atenção, você está se candidatando para uma vaga com outro tipo de PCD."
          centered
          visible={confirmationVisible}
          onCancel={handleApply}
          cancelText="Sim"
          closeIcon
          maskClosable={false}
          okText="Não"
          onOk={() => setConfirmationVisible(false)}
          style={{
            top: 30,
          }}
          width={600}
          okButtonProps={{
            type: "default",
          }}
          cancelButtonProps={{
            type: "primary",
          }}
        >
          <p>
            Olá, {user?.pes_nome}, você possui o tipo de PCD {user?.pcd_tipo} e
            está se candidatando para uma vaga PCD do tipo {vacancy?.pcd_tipo},
            você tem certeza que deseja seguir com esta ação?
          </p>
        </Modal>
        <Modal
          title="Atenção, você está se candidatando para uma vaga que não é PCD."
          centered
          visible={confirmationNotVisible}
          onCancel={handleApply}
          cancelText="Sim"
          closeIcon
          maskClosable={false}
          okText="Não"
          onOk={() => setConfirmationNotVisible(false)}
          style={{
            top: 30,
          }}
          width={600}
          okButtonProps={{
            type: "default",
          }}
          cancelButtonProps={{
            type: "primary",
          }}
        >
          <p>
            Olá, {user?.pes_nome}, você possui o tipo de PCD {user?.pcd_tipo} e
            está se candidatando para uma vaga não PCD, você tem certeza que
            deseja seguir com esta ação?
          </p>
        </Modal>
      </Container>
    </>
  ) : (
    <Redirect to={`/${account}`} />
  );
};

export default Vacancy;
