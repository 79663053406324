import styled, { keyframes } from "styled-components";

import { shade } from "polished";

import signInBackgroundImg from "../../assets/default_background.jpg";

export const Container = styled.div`
  height: 100vh;
  display: flex;
  align-items: stretch;
  width: 100%;
  min-height: 600px;
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  width: 100%;
  max-width: 700px;
  padding: 0 20px;
  height: 100%;
  min-height: 600px;
`;

const appearFromLeft = keyframes`
  from {
    opacity: 0;
    transform: translateX(-50px);
  }
  to {
    opacity: 1;
    transform: translateX(0);
  }
`;

export const AnimationContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  max-width: 100%;

  height: 100%;
  flex-direction: column;
  margin-top: auto;
  margin-bottom: auto;

  animation: ${appearFromLeft} 1s;

  > img {
    min-height: 70px;
    max-width: 90%;
    max-height: 20%;
  }

  form {
    width: 100%;
    margin: 40px 0px 16px;
    max-width: 340px;
    align-items: center;
    text-align: center;

    > .ant-form-item {
      max-width: 100%;

      .ant-input-prefix {
        margin-right: 11px;
      }
    }

    > button {
      width: 100%;
    }

    h1 {
      margin-bottom: 24px;
    }

    a {
      color: ${(props) => props.theme["font.100"]};
      display: block;
      margin-top: 24px;
      text-decoration: none;
      transition: filter 0.2s;

      &:hover {
        filter: brightness(2);
      }
    }
  }

  > a {
    color: var(--primary);
    margin-top: 24px;
    text-decoration: none;
    transition: color 0.2s;
    display: flex;
    align-items: center;

    &:hover {
      color: ${(props) => shade(0.2, props.theme.primary)};
    }

    svg {
      margin-right: 16px;
    }
  }
`;

interface BackgroundProps {
  background: any;
}

export const Background = styled.div<BackgroundProps>`
  flex: 1;
  background: url(${(props) => props.background}) no-repeat center;
  background-size: cover;
  flex-direction: column;
  margin: 0;
  margin-bottom: 0;
`;
