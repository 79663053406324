import React, { useCallback, useMemo, useState } from "react";

import {
  DashboardOutlined,
  MenuUnfoldOutlined,
  UserOutlined,
  SafetyOutlined,
  ProfileOutlined,
} from "@ant-design/icons";

import { Avatar, Button, Divider, Menu } from "antd";

import { Link, useLocation } from "react-router-dom";

import { animateScroll } from "react-scroll";

import {
  SiderLogoContainer,
  Layout,
  HeaderAvatar,
  Dropdown,
  AvatarContainer,
  ProfileCard,
  ProfileCardHeader,
  Unfold,
  HeaderLogoContainer,
  SiderTriggeredFilter,
  Logo,
} from "./styles";

import logoImg from "../../assets/logo.png";

import { useAuth } from "../../hooks/auth";
import { useTheme } from "../../hooks/theme";

const { Header, Content, Footer, Sider } = Layout;

interface MainLayoutProps {
  component: React.ComponentType;
}

const MainLayout: React.FC<MainLayoutProps> = ({
  component: Component,
  ...props
}) => {
  const { params } = useTheme();

  const { user, signOut } = useAuth();

  const [collapsed, setCollapsed] = useState(true);

  const [isBroken, setIsBroken] = useState(false);

  const username = useMemo((): string => {
    const nameSplitted = user.pes_nome.split(" ");

    if (nameSplitted.length > 1) {
      return `${nameSplitted[0]} ${nameSplitted[1].slice(0, 1)}`;
    }

    return nameSplitted[0];
  }, [user.pes_nome]);

  const handleToggleCollapse = useCallback(() => {
    setCollapsed((state) => !state);
  }, []);

  const logo = useMemo(() => {
    return params && params.logo ? params.logo : logoImg;
  }, [params]);

  const logoImgComponent = useMemo(() => {
    return <img style={{ maxHeight: 70 }} src={logo} alt="Logo TeamHub" />;
  }, [logo]);

  const unfoldComponent = useMemo(
    () =>
      isBroken ? (
        <></>
      ) : (
        <Unfold>
          <MenuUnfoldOutlined onClick={handleToggleCollapse} />
        </Unfold>
      ),
    [handleToggleCollapse, isBroken]
  );

  const scrollToTop = useCallback(() => {
    animateScroll.scrollToTop();
  }, []);

  const location = useLocation();

  return (
    <Layout>
      <Sider
        breakpoint="lg"
        width={240}
        collapsedWidth="0"
        style={{ position: "fixed", height: "100vh", zIndex: 999 }}
        onBreakpoint={(broke) => {
          setIsBroken(broke);
          if (broke) {
            setCollapsed(broke);
          }
        }}
        defaultCollapsed
        collapsed={collapsed}
        onCollapse={(_, type) => {
          if (type === "clickTrigger") {
            handleToggleCollapse();
          }
        }}
      >
        <SiderLogoContainer>
          {unfoldComponent}
          {logoImgComponent}
        </SiderLogoContainer>

        <Menu
          mode="inline"
          defaultSelectedKeys={
            location.pathname === `/${user.account.conta_url}/dashboard`
              ? ["1"]
              : ["2"]
          }
          onClick={() => setCollapsed(true)}
        >
          <Menu.Item key="2">
            <Link to={`/${user.account.conta_url}/profile`}>
              <UserOutlined /> Perfil
            </Link>
          </Menu.Item>
          <Menu.Item key="3">
            <Link to={`/${user.account.conta_url}/password`}>
              <SafetyOutlined /> Redefinir Senha
            </Link>
          </Menu.Item>
          <Menu.Item key="1">
            <Link to={`/${user.account.conta_url}/dashboard`}>
              <DashboardOutlined /> Dashboard
            </Link>
          </Menu.Item>
        </Menu>
      </Sider>
      <Layout {...(collapsed ? { style: { overflow: "hidden" } } : {})}>
        {!collapsed && (
          <SiderTriggeredFilter onClick={() => setCollapsed(true)} />
        )}
        <Header>
          <HeaderLogoContainer>
            {unfoldComponent}
            <Logo
              onClick={() => {
                scrollToTop();
              }}
            >
              {logoImgComponent}
            </Logo>
          </HeaderLogoContainer>

          <HeaderAvatar>
            <Dropdown
              overlay={() => (
                <ProfileCard>
                  <ProfileCardHeader>
                    <Avatar size={64} icon={<UserOutlined />} />
                    <div>
                      <span>{username}</span>
                      <span>{user.pes_email}</span>
                    </div>
                  </ProfileCardHeader>
                  <Divider />
                  <Button type="primary" onClick={signOut}>
                    Sign Out
                  </Button>
                </ProfileCard>
              )}
            >
              <AvatarContainer
                tabIndex={-1}
                role="button"
                className="ant-dropdown-link"
                onClick={(e): void => e.preventDefault()}
                onKeyDown={() => true}
              >
                <Avatar size={36} icon={<UserOutlined />} />
              </AvatarContainer>
            </Dropdown>
          </HeaderAvatar>
        </Header>
        <Content>
          <Component {...props} />
        </Content>
        <Footer>TeamHub ©{new Date().getFullYear()}</Footer>
      </Layout>
    </Layout>
  );
};

export default MainLayout;
