/* eslint-disable prettier/prettier */
import React, { useCallback, useState } from 'react'
import { Modal } from 'antd';

import ChangePassword from 'components/changePassword';
import { toast } from 'react-toastify';
import { useHistory } from 'react-router-dom';
import { useAuth } from 'hooks/auth';

import api from "../../services/api";

interface IPassword {
    senha_alterada: number;
  }

const Password: React.FC = () => {
    return(
        <>
            <ChangePassword />
        </>
    )
}

export default Password;