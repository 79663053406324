import api from "services/apiGc";

import { PesquisasData, GetPesquisaResponse } from "ts/pesquisas/types";

const GET_PESQUISAS_URL = "/pesquisa/candidate";

export const getPesquisas = async ({
  emp_id,
  pes_id,
  conta_id,
}: {
  emp_id: number;
  pes_id: number;
  conta_id: number;
}): Promise<PesquisasData> => {
  try {
    const response = await api.get(GET_PESQUISAS_URL, {
      params: {
        pes_id,
        conta_id,
        emp_id,
      },
    });

    const { status, data }: { status: number; data: GetPesquisaResponse } =
      response;

    if (status === 204) return { pesquisas: [] };
    if (status === 500)
      throw new Error("Ocorreu um erro ao tentar listar pesquisas.");

    const { pesquisas, message } = data;
    if (status !== 200) {
      if (message) return { error: message };
      throw new Error("Unknown Error.");
    }

    return { pesquisas };
  } catch (err: any) {
    return { error: err.toString() };
  }
};
