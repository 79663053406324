import axios, { AxiosInstance } from "axios";

import { getToken, logout } from "./auth";

const api = (): AxiosInstance => {
  const instance = axios.create({
    baseURL: process.env.REACT_APP_API_URL_GESTAOCULTURA,
    headers: {
      "Content-Type": "application/json",
    },
  });

  instance.interceptors.request.use(
    (request) => {
      request.headers.authorization = `Bearer ${getToken()}`;
      return request;
    },
    (error) => {
      return Promise.reject(error);
    }
  );

  instance.interceptors.response.use(
    (response) => response,
    (error) => {
      if (error.response.status === 401) {
        logout();
      }
      return Promise.reject(error);
    }
  );

  return instance;
};

export default api();
