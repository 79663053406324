import styled, { css, keyframes } from "styled-components";

import { lighten, shade } from "polished";

import {
  Menu as AntdMenu,
  Layout as AntdLayout,
  Carousel as AntdCarousel,
} from "antd";

export type HeaderColors = "transparent" | "primary";

interface ILayoutProps {
  headercolor: HeaderColors;
}

const appearFromLeft = keyframes`
  from {
    opacity: 0;
    transform: translateX(-50px);
  }
  to {
    opacity: 1;
    transform: translateX(0);
  }
`;

const appearFromRight = keyframes`
  from {
    opacity: 0;
    transform: translateX(50px);
  }
  to {
    opacity: 1;
    transform: translateX(0);
  }
`;

export const Layout = styled(AntdLayout)<ILayoutProps>`
  min-height: 100vh;
  width: 100%;
  max-width: 100%;
  overflow-x: hidden;

  /* background-color: ${(props) => props.theme["background.600"]}; */

  .ant-menu-submenu {
    color: #262626;
  }

  .ant-layout-footer {
    color: ${(props) => props.theme.header};
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #fff;
    font-size: 1rem;
    height: 60px;
    border-top: 1px solid rgba(33, 35, 38, 0.1);
    color: #262626;

    @media screen and (max-width: 768px) {
      padding: 24px 20px;
    }

    @media screen and (max-width: 300px) {
      padding: 24px 5px;
    }
  }

  .ant-layout-header {
    position: fixed;
    height: 94px;
    width: 100vw;
    margin: 0 !important;
    margin-bottom: 24px;
    z-index: 999;

    .ant-menu {
      background: transparent;
    }

    transition: background 0.3s;
    transition: box-shadow 0.3s;

    ${(props) =>
      props.headercolor &&
      props.headercolor === "transparent" &&
      css`
        background: transparent;
        .ant-menu-item {
          a {
            color: #262626;
          }
        }
      `}

    ${(props) =>
      props.headercolor &&
      props.headercolor === "primary" &&
      css`
        background: ${props.theme.header} !important;
        box-shadow: rgba(33, 35, 38, 0.1) 0px 10px 10px -10px;
        .ant-menu-item {
          a {
            color: #262626;
          }
        }
      `}
  }

  .ant-layout-content {
    display: flex;
    flex-direction: column;
    align-items: stretch;
  }

  h1 {
    font-size: 32px;
  }
`;

export const Logo = styled.div`
  float: left;
  width: 120px;
  height: 31px;
  margin: 16px 24px 16px 0;

  display: flex;
  align-items: center;
  justify-content: center;

  > img {
    max-width: 120px;
    max-height: 70px;
  }
  &:hover {
    cursor: pointer;
  }
`;

export const SignInButton = styled(AntdMenu.Item)`
  &:hover {
    background-color: unset !important;
  }
`;

export const Menu = styled(AntdMenu)`
  display: flex;
  justify-content: flex-end;

  .ant-menu-item {
    height: 79px;
    font-size: 1rem;
    color: #262626;
    transition: color 0.3s;

    button {
      font-size: 1rem;

      span {
        font-size: 1rem;
      }
    }

    border-bottom: 3px solid transparent !important;

    &:hover {
      &,
      a {
        color: #1890ff !important;
        background-color: unset !important;
      }

      border-bottom: 3px solid var(--primary) !important;
    }
  }

  .ant-menu-item-selected,
  .ant-menu-item-active {
    &,
    a {
      color: var(--primary);
      background-color: unset !important;
    }
    border-bottom: 3px solid var(--primary) !important;
  }

  ${SignInButton} {
    cursor: default;
    border: none !important;
    &:hover {
      background-color: unset !important;
      border: none !important;
    }

    .ant-btn {
      padding: 0;
    }
  }
`;

interface BannerProps {
  background: any;
}

export const Banner = styled.div<BannerProps>`
  display: flex;
  position: relative;
  overflow: hidden;
  min-width: 100%;

  > .banner-image {
    flex: 1;
    margin: 94px -6px -6px -3px;
    background: url(${(props) => props.background}) no-repeat center;
    background-size: cover;
    height: 300px;
    animation: ${appearFromLeft} 1s;
  }

  > .banner-filter {
    position: absolute;
    background: rgba(0, 0, 0, 0.55);
    width: 100%;
    height: 500px;
  }

  @media screen and (min-width: 768px) {
    > .banner-filter {
      height: 500px;
    }

    > .banner-image {
      max-height: 600px;
      min-height: 400px;
    }
  }

  @media screen and (max-width: 600px) {
    > .banner-image {
      max-height: 150px;
    }
  }

  > .banner-text {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    padding: 0 20px;

    > img {
      width: 100%;
      max-width: 450px;

      & + h2 {
        margin-top: 32px;
      }
    }

    > h2 {
      font-size: 36px;
      text-align: center;
      display: none;
      color: var(--light-gray2);
    }

    @media screen and (min-width: 768px) {
      > img {
        width: 500px;
      }

      > h2 {
        display: block;
      }
    }
  }
`;

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 24px;
  background-color: ${(props) => props.theme["background.800"]};

  > section {
    flex: 1;
    width: 100%;
    max-width: 1120px;
  }
`;

export const AboutUsContainer = styled(Container)`
  animation: ${appearFromRight} 1s;
`;

export const AboutUs = styled.section`
  display: flex;
  flex-direction: column;

  > div:first-of-type {
    display: flex;
    justify-content: center;

    > h1 {
      color: ${(props) => props.theme["font.800"]};
    }
  }

  > div:nth-of-type(2) {
    > p,
    span,
    text {
      color: ${(props) => props.theme["font.400"]};
    }
  }
`;

export const JobsContainer = styled(Container)`
  animation: ${appearFromLeft} 1s;
`;

export const Jobs = styled.section`
  display: flex;
  flex-direction: column;
  align-items: stretch;

  > .ant-list {
    overflow-x: hidden;
  }

  @media screen and (min-width: 768px) {
    > .ant-list {
      max-height: 700px;
      overflow-x: auto;
    }
  }
`;

export const JobsHeader = styled.div`
  display: flex;
  flex-direction: column;

  > div:first-of-type {
    display: flex;
    justify-content: center;

    > h1 {
      color: ${(props) => props.theme["font.800"]};
    }
  }

  > div:nth-of-type(2) {
    > p,
    span,
    text {
      color: ${(props) => props.theme["font.400"]};
    }
  }

  margin: 0 0 16px 0;
`;

export const JobTitle = styled.div`
  display: flex;
  justify-content: center;
  transition: color linear 0.2s;

  @media screen and (min-width: 768px) {
    justify-content: flex-start;
  }

  > span {
    font-size: 22px;
    font-weight: 500;
    color: ${(props) => props.theme["font.200"]};
    transition: color linear 0.2s;
  }
`;

export const JobDetails = styled.div`
  display: flex;
  flex-direction: column;
  color: ${(props) => props.theme.gray1};
  transition: color linear 0.2s;

  > div {
    & + div {
      margin: 0;
      margin-top: 4px;
    }

    display: flex;
    flex-direction: row;
    align-items: center;

    justify-content: center;

    @media screen and (min-width: 768px) {
      justify-content: flex-start;
    }

    > svg {
      & + span {
        margin-left: 8px;
      }
      & + time {
        margin-left: 8px;
      }
    }

    > time,
    span {
      font-size: 16px;
    }
  }

  @media screen and (min-width: 768px) {
    flex-direction: row;
    align-items: center;

    > div {
      & + div {
        margin: 0;
        margin-left: 16px;
      }
    }
  }
`;

export const JobWrapper = styled.div`
  padding: 9px 12px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const JobsItem = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  padding: 18px 24px;
  width: 100%;

  box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px,
    rgba(60, 64, 67, 0.15) 0px 1px 3px 1px;

  ${JobTitle} {
    > span {
      color: ${(props) => props.theme.primary};
    }
  }

  transition: transform 0.2s ease-in-out;

  &:hover {
    cursor: pointer;
    transform: scale(1.02);
  }
`;

export const Carousel = styled(AntdCarousel)`
  flex: 1;
  height: 250px;
  max-width: 100%;

  img {
    width: 100%;
    height: 250px;
    object-fit: cover;
  }

  .react-player {
    width: 100%;
    height: 250px;

    > div {
      width: 100%;
      max-width: 100%;
      height: 100%;
      max-height: 100%;
    }
  }

  @media screen and (min-width: 768px) {
    height: 500px;

    img {
      height: 500px;
    }

    .react-player {
      height: 500px;
    }
  }

  .slick-prev,
  .slick-next {
    color: unset;
    font-size: unset;

    :focus {
      color: unset;
    }

    color: ${(props) => props.theme["font.800"]} !important;
    &:hover {
      color: var(--gray1) !important;
    }
  }
`;

export const WrapperRedesSociais = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  background: white;
`;

export const VacancyDescription = styled.div`
  display: flex;
  flex-direction: column;
  align-items: stretch;
  min-height: 0;
  background-color: var(--black-lighter);

  margin-top: 8px;

  p {
    color: var(--light-gray2);
    font-size: 18px;
  }

  strong,
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    font-weight: 500;
    color: var(--primary-shaded);
  }

  strong {
    font-size: 20px;
  }

  h1 {
    color: black;
    font-size: 26px;
  }

  h2 {
    color: black;
    font-size: 24px;
  }

  h3 {
    color: black;
    font-size: 24px;
  }

  h4 {
    color: black;
    font-size: 22px;
  }

  h5 {
    color: black;
    font-size: 22px;
  }

  h6 {
    color: black;
    font-size: 20px;
  }
`;

export const ImagemBanner = styled.img`
  @media screen and (min-width: 1280px) {
    object-fit: cover;
    max-height: 600px;
  }
`;
