import React, { useCallback } from "react";

import { Result, Button } from "antd";

import { useHistory } from "react-router-dom";

import { Container } from "./styles";

const NotFound: React.FC = () => {
  const history = useHistory();

  const handleGoBack = useCallback(() => {
    history.goBack();
  }, [history]);

  return (
    <Container>
      <Result
        status="404"
        title="404"
        subTitle="A página solicitada não existe."
        extra={
          <Button type="primary" onClick={() => handleGoBack()}>
            Voltar
          </Button>
        }
      />
    </Container>
  );
};

export default NotFound;
