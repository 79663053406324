import { combineReducers } from "@reduxjs/toolkit";

import pesquisasReducer from "./modules/pesquisas/pesquisasSlice";

const appReducer = combineReducers({
  pesquisas: pesquisasReducer,
});

const rootReducer = (
  state: any,
  action: any
): ReturnType<typeof appReducer> => {
  if (action.type === "DESTROY_REDUX") {
    // eslint-disable-next-line no-param-reassign
    state = undefined;
  }
  return appReducer(state, action);
};

export type RootState = ReturnType<typeof rootReducer>;

export type RootType = typeof rootReducer;

export default rootReducer;
