import styled from "styled-components";

import { Layout as AntdLayout, Dropdown as AntdDropdown } from "antd";

import { darken, lighten, shade } from "polished";

export const Layout = styled(AntdLayout)`
  min-height: 100vh;
  width: 100%;

  background-color: ${(props) => props.theme["background.600"]};

  .ant-layout-sider {
    background-color: ${(props) => props.theme["background.800"]};

    .ant-menu-dark {
      background: ${(props) => props.theme["background.800"]};

      .ant-menu-item {
        margin: 0;
      }
    }

    .ant-layout-sider-zero-width-trigger {
      top: 94px;
      height: 40px;
      background: ${(props) => props.theme["background.800"]};
    }
  }

  .ant-layout-header {
    width: 100vw;
    position: fixed;
    left: 0;
    height: 94px;
    background: ${(props) => props.theme["background.800"]};
    z-index: 997;

    padding: 0 24px;

    display: flex;
    flex-direction: row;
    align-items: center;

    justify-content: flex-end;
    box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px,
      rgba(60, 64, 67, 0.15) 0px 1px 3px 1px;
  }

  .ant-layout-footer {
    background: ${(props) => props.theme["background.800"]};
    height: 94px;

    display: flex;
    justify-content: center;
    align-items: center;
  }

  .ant-layout-content {
    margin-top: 94px;
    padding: 24px 16px;
  }
`;

export const HeaderAvatar = styled.div`
  display: flex;
  align-items: center;
`;

export const Dropdown = styled(AntdDropdown)`
  line-height: 48px;
  display: flex;
  align-items: center;
`;

export const AvatarContainer = styled.div`
  cursor: pointer;
`;

export const ProfileCard = styled.div`
  display: flex;
  flex-direction: column;
  align-items: stretch;
  margin-top: 16px;
  background-color: ${(props) => props.theme["background.800"]};
  box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px,
    rgba(60, 64, 67, 0.15) 0px 1px 3px 1px;

  > button {
    width: 100%;
    padding: 12px 0;
    height: 48px;

    > span {
      font-weight: 500;
    }

    transition: background-color 0.2s linear;
  }

  .ant-divider {
    flex: 1;
    width: auto;
    border-color: ${(props) => props.theme["background.600"]};
    border-width: 2px;
    margin: 0px;
  }
`;

export const ProfileCardHeader = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 12px 24px;

  > div {
    margin-left: 16px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;

    > span {
      & + span {
        margin-top: 4px;
      }

      &:first-of-type {
        font-weight: 500;
        font-size: 18px;
      }

      &:nth-last-of-type(1) {
        color: var(--gray5);
      }
    }
  }
`;

export const HeaderLogoContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-right: auto;

  > img {
    max-width: 120px;
    max-height: 70px;
  }
`;

export const SiderLogoContainer = styled.div`
  padding: 10px 0;
  height: 94px;
  display: flex;
  align-items: center;
  justify-content: center;
  vertical-align: baseline;

  background: ${(props) => props.theme["background.800"]};

  > img {
    max-width: 120px;
    max-height: 70px;
  }
`;

export const Unfold = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  vertical-align: baseline;

  margin-right: 24px;

  span {
    font-size: 18px;
    line-height: 48px;
    padding: 0;
    cursor: pointer;
    transition: color 0.3s;
    display: flex;
    align-items: center;

    :hover {
      color: var(--pink);
    }
  }
`;

export const SiderTriggeredFilter = styled.div`
  position: fixed;
  width: 100vw;
  height: 100vh;
  background: rgba(0, 0, 0, 0.45);
  z-index: 998;
  cursor: pointer;
`;

export const Logo = styled.div`
  > img {
    max-width: 120px;
    max-height: 70px;
  }
  &:hover {
    cursor: pointer;
  }
`;
