/* eslint-disable prettier/prettier */
import React, { useCallback, useState } from 'react'
import { Button, Card, Col, Form, Input } from "antd";
import { FormFlexItem, FormFlexRow, Container, ButtonContainer, GoBack } from 'pages/Profile/styles';
import { useHistory } from 'react-router-dom';

import { toast } from 'react-toastify';
import { useAuth } from 'hooks/auth';

import api from "../../services/api";
import { IProfile } from '../../pages/Profile';

interface IPassword {
    senha_alterada: number;
}

type change = {
    onFinish: (arg0: IPassword) => Promise<void>
}

export default function ChangePassword() {
    const history = useHistory();
    const { user } = useAuth();

    const [form] = Form.useForm()

    const [updatingPassword, setUpdatingPassword] = useState(false);

    const handleUpdatePassword = useCallback(
        async (data: IPassword) => {
        setUpdatingPassword(true);
        try {
            const response = await api.put<IProfile>(`${user.account.conta_url}/profile`, { ...data })
            if (response.status === 200) {
                console.log('response data ', response.data)
            toast.success(
                "Senha atualizado com sucesso, redirecionando para a Dashboard em 5 segundos."
            );
            setTimeout(() => {
                history.push(`/${user.account.conta_url}/dashboard`);
            }, 5000);
            }
        } finally {
            setUpdatingPassword(false)
        }
        setUpdatingPassword(false);
        },
        []
    );

    return(
        <Container>
            <Col xs={24} sm={24} md={16} lg={12}>
                <GoBack onClick={() => history.goBack()}>Voltar</GoBack>
                <Form
                    form={form}
                    layout="vertical"
                    id="profile-form"
                    onFinish={handleUpdatePassword}
                    scrollToFirstError
                >
                    <Card title="Redefinir senha">
                        <FormFlexRow>
                            <FormFlexItem width={50}>
                                <Form.Item name="old_password" label="Senha atual">
                                    <Input
                                        type="password"
                                        placeholder="Digite sua senha atual"
                                        size="large"
                                    />
                                </Form.Item>
                            </FormFlexItem>

                            <FormFlexItem width={50}>
                                <Form.Item name="password" label="Nova senha">
                                    <Input
                                        type="password"
                                        placeholder="Digite a nova senha"
                                        size="large"
                                    />
                                </Form.Item>
                            </FormFlexItem>
                        </FormFlexRow>
                        <ButtonContainer>
                            <Button
                                type="primary"
                                size="large"
                                htmlType="submit"
                                form="profile-form"
                                loading={updatingPassword}
                                disabled={updatingPassword}
                            >
                                Salvar alterações
                            </Button>
                        </ButtonContainer>
                    </Card>
                </Form>
            </Col>
        </Container>       
    )
}    