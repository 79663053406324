/* eslint-disable prettier/prettier */
import React, { useCallback, useEffect, useMemo, useState } from "react";

import moment from "moment";

import {
  Form,
  Input,
  Tabs,
  Button,
  Select,
  DatePicker,
  Popconfirm,
  Upload,
  Card,
  Typography,
  Col,
  Checkbox,
  Row,
  Alert,
  Modal,
} from "antd";

import {
  LinkedinOutlined,
  TwitterOutlined,
  LinkOutlined,
  UploadOutlined,
  YoutubeOutlined,
  LockOutlined,
} from "@ant-design/icons";

import { toast } from "react-toastify";

import { useHistory } from "react-router";
import { useLocation } from "react-router-dom";
import { UploadFile } from "antd/lib/upload/interface";
import {
  Container,
  FormFlexItem,
  FormFlexRow,
  ButtonContainer,
  GoBack,
} from "./styles";

import api from "../../services/api";

import apiGc from "../../services/apiGc";

import { useAuth } from "../../hooks/auth";

import ChangePassword from "../../components/changePassword"

import { sorterString } from "../../utils/utils";

const { TabPane } = Tabs;
const { Option } = Select;
export interface IProfile {
  emp_id: number;
  conta_id: number;
  pes_id: number;
  pes_nome: string;
  pes_email: string;
  pes_cpf: string;
  escolaridade: string;
  pes_sexo: string;
  pes_datanascimento: Date;
  pes_cel: string;
  pes_ativo: number;
  pes_foto: string;
  pes_login: string;
  ativorelatorios: number;
  datacriacao: Date;
  dataadmissao: Date;
  datademissao: Date;
  profissao: string;
  prtsalario: number;
  indicacao: string;
  linkedin: string;
  twitter: string;
  site: string;
  curriculo: string;
  curriculum_url?: string;
  pcd_laudo: string;
  pcd_laudo_url?: string;
  filial_id: number;
  cep: string;
  logradouro: string;
  nro: number;
  complemento: string;
  bairro: string;
  ufid: number;
  cidadeid: number;
  pcd: number;
  pcd_tipo: string;
  cid: string;
  termo_aceito: number;
  account: {
    conta_razaonome: string;
    conta_url: string;
  };
}

interface IHandleUpload {
  name: string;
  file: any;
  onSuccess: any;
  onError: any;
  onProgress: any;
}

interface VacancyStepCandidate {
  etapa_id: number;
  pes_id: number;
  contratado: string;
  excluido: string;
}

interface VacancyStep {
  etapa_id: number;
  etapa: string;
  ordem: number;
  stepCandidates: VacancyStepCandidate[];
}

interface PsParametros {
  coluna: string;
  conta_id: number;
  emp_id: number;
  obrigatorio: number;
  param_id: number;
  processo_id: number;
  tipo: string;
}

interface Vacancy {
  pcd?: number;
  pcd_tipo?: string;
  steps: VacancyStep[];
  psparametros: PsParametros[];
}

const Profile: React.FC = () => {
  const history = useHistory();

  const location = useLocation<{ vacancy_id?: number }>();

  const { user, updateUser, signOut } = useAuth();

  const [form] = Form.useForm();

  const { Text, Title, Link } = Typography;

  const [profileInfo, setProfileInfo] = useState<IProfile>();

  const [currentCurriculum, setCurrentCurriculum] = useState("");

  const [currentLaudo, setCurrentLaudo] = useState("");

  const [loadingProfile, setLoadingProfile] = useState(false);

  const [modalPasswordOpen, setModalPasswordOpen] = useState(false);

  const [updatingProfile, setUpdatingProfile] = useState(false);

  const [uploadingCurriculum, setUploadingCurriculum] = useState(false);

  const [uploadingPCDLaudo, setUploadingPCDLaudo] = useState(false);

  const [loadingUfs, setloadingUfs] = useState(false);

  const [ufs, setUfs] = useState([]);

  const [currentUf, setCurrentUf] = useState<number>();

  const [loadingCidades, setLoadingCidades] = useState(false);

  const [cidades, setCidades] = useState([]);

  const [todasCidades, setTodasCidades] = useState([]);

  const [pessoaComDeficiencia, setPessoaComDeficiencia] = useState(false);

  const [checkPCD, setCheckPCD] = useState(false);

  const [vacancies, setVacancies] = useState<Vacancy[]>([]);

  const [isPCDFile, setIsPCDFile] = useState(false);

  const [fieldsToFill, setFieldsToFill] = useState(false);

  const [curriculoUploaded, setCurriculoUploaded] = useState(false);

  /* Campos inicio */
  const [checkNomeCompleto, setNomeCompleto] = useState(false);
  const [checkEmail, setEmail] = useState(false);
  const [checkCPF, setCPF] = useState(false);
  const [checkGenero, setGenero] = useState(false);
  const [checkDataNascimento, setDataNascimento] = useState(false);
  const [checkCelular, setCelular] = useState(false);

  const [checkEnderecoCompleto, setEnderecoCompleto] = useState(false);
  const [checkCEP, setCEP] = useState(false);
  const [checkLogradouro, setLogradouro] = useState(false);
  const [checkBairro, setBairro] = useState(false);
  const [checkNumero, setNumero] = useState(false);
  const [checkComplemento, setComplemento] = useState(false);
  const [checkEstado, setEstado] = useState(false);
  const [checkCidade, setCidade] = useState(false);

  const [checkProfissao, setProfissao] = useState(false);
  const [checkPretensaoSalarial, setPretensaoSalarial] = useState(false);
  const [checkLinkedin, setLinkedin] = useState(false);
  const [checkTwitter, setTwitter] = useState(false);
  const [checkPortfolio, setPortfolio] = useState(false);
  const [checkIndicacao, setIndicacao] = useState(false);
  const [checkVideo, setVideo] = useState(false);
  const [checkCurriculo, setCurriculo] = useState(false);
  /* Campos final */

  const getVerificationFieldsToFill = useCallback(async () => {
    try {
      await api
        .get(`${user.account.conta_url}/dashboard/check-has-required-fields`)
        .then((response) => {
          if (response.status === 200) {
            setFieldsToFill(response.data);
          }
        });
    } catch (err: any) {
      toast.error(err.data.message);
    }
  }, [user.account.conta_url]);

  const getProfileInfo = useCallback(async () => {
    setLoadingProfile(true);

    try {
      const response = await api.get(`${user.account.conta_url}/profile`);

      if (response.status === 200) {
        setProfileInfo(response.data);
        console.log("LOG", response.data);

        setCurrentCurriculum(response.data.curriculum_url);
        setCurrentLaudo(response.data.pcd_laudo_url);
        response.data.pcd_laudo_url ? setIsPCDFile(true) : setIsPCDFile(false);
        const { pcd } = response.data;
        if (pcd === 1) {
          setPessoaComDeficiencia(true);
          setCheckPCD(true);
        }
        if (response.data.curriculo) {
          setCurriculoUploaded(true);
        }
      }
    } finally {
      setLoadingProfile(false);
    }
  }, [user.account.conta_url]);

  const getProfileUpload = useCallback(async () => {
    setLoadingProfile(true);

    try {
      const response = await api.get(`${user.account.conta_url}/profile`);

      if (response.status === 200) {
        setCurrentCurriculum(response.data.curriculum_url);
        setCurrentLaudo(response.data.pcd_laudo_url);
        response.data.pcd_laudo_url ? setIsPCDFile(true) : setIsPCDFile(false);
        toast.success("Arquivo atualizado com sucesso.");
        const { pcd } = response.data;
        if (pcd === 1) {
          setPessoaComDeficiencia(true);
          setCheckPCD(true);
        }
        if (response.data.curriculo) {
          setCurriculoUploaded(true);
        }
      }
    } finally {
      setLoadingProfile(false);
    }
  }, [user.account.conta_url]);

  const getVacanciesInfo = useCallback(async () => {
    setLoadingProfile(true);
    try {
      const response = await api.get(`${user.account.conta_url}/dashboard`);

      if (response.status === 200) {
        setVacancies(response.data);
      }
    } finally {
      setLoadingProfile(false);
    }
  }, [user.account.conta_url]);

  const loadUfs = useCallback(async () => {
    setloadingUfs(true);

    try {
      const response = await apiGc.get("/uf");

      if (response.status === 200) {
        const { ufs: ufsData } = response.data;

        setUfs(ufsData);
      }
    } finally {
      setloadingUfs(false);
    }
  }, []);

  const loadCidades = useCallback(async (ufid: number) => {
    setLoadingCidades(true);

    try {
      const response = await apiGc.get(`/cidade/${ufid}`);

      if (response.status === 200) {
        const { cidades: cidadesData } = response.data;

        setCidades(cidadesData);
      }
    } finally {
      setLoadingCidades(false);
    }
  }, []);

  const loadTodasCidades = useCallback(async () => {
    try {
      const response2 = await apiGc.get(`/cidades`);

      if (response2.status === 200) {
        setTodasCidades(response2.data);
      }
    } finally {
      setLoadingCidades(false);
    }
  }, []);

  const parametrosObrigatorios = useCallback(async () => {
    let camposObrigatorios = [];

    try {
      const response = await api.get(
        `${user.account.conta_url}/dashboard/required-fields`
      );

      if (response.status === 200) {
        camposObrigatorios = response.data;
      }
    } catch (err: any) {
      toast.error(err.data.message);
    }

    camposObrigatorios.map((params: any) => {
      const hasFieldToComplete = false;
      if (
        params.coluna === "check_endereco_completo" &&
        params.obrigatorio === 1
      ) {
        setEnderecoCompleto(true);
      }

      if (params.coluna === "check_cep" && params.obrigatorio === 1) {
        setCEP(true);
      }

      if (params.coluna === "check_logradouro" && params.obrigatorio === 1) {
        setLogradouro(true);
      }

      if (params.coluna === "check_bairro" && params.obrigatorio === 1) {
        setBairro(true);
      }

      if (params.coluna === "check_numero" && params.obrigatorio === 1) {
        setNumero(true);
      }

      if (params.coluna === "check_complemento" && params.obrigatorio === 1) {
        setComplemento(true);
      }

      if (params.coluna === "check_estado" && params.obrigatorio === 1) {
        setEstado(true);
      }

      if (params.coluna === "check_cidade" && params.obrigatorio === 1) {
        setCidade(true);
      }

      if (params.coluna === "check_nome_completo" && params.obrigatorio === 1) {
        setNomeCompleto(true);
      }

      if (params.coluna === "check_email" && params.obrigatorio === 1) {
        setEmail(true);
      }

      if (params.coluna === "check_cpf" && params.obrigatorio === 1) {
        setCPF(true);
      }

      if (params.coluna === "check_genero" && params.obrigatorio === 1) {
        setGenero(true);
      }

      if (
        params.coluna === "check_data_nascimento" &&
        params.obrigatorio === 1
      ) {
        setDataNascimento(true);
      }

      if (params.coluna === "check_celular" && params.obrigatorio === 1) {
        setCelular(true);
      }

      if (params.coluna === "check_profissao" && params.obrigatorio === 1) {
        setProfissao(true);
      }

      if (
        params.coluna === "check_pretensao_salarial" &&
        params.obrigatorio === 1
      ) {
        setPretensaoSalarial(true);
      }

      if (params.coluna === "check_linkedin" && params.obrigatorio === 1) {
        setLinkedin(true);
      }

      if (params.coluna === "check_twitter" && params.obrigatorio === 1) {
        setTwitter(true);
      }

      if (
        params.coluna === "check_site_portfolio" &&
        params.obrigatorio === 1
      ) {
        setPortfolio(true);
      }

      if (params.coluna === "check_indicacao" && params.obrigatorio === 1) {
        setIndicacao(true);
      }

      if (
        params.coluna === "check_video_apresentacao" &&
        params.obrigatorio === 1
      ) {
        setVideo(true);
      }

      if (params.coluna === "check_curriculo" && params.obrigatorio === 1) {
        setCurriculo(true);
      }

      return hasFieldToComplete;
    });
  }, [user]);

  useEffect(() => {
    getProfileInfo();
    loadUfs();
    loadTodasCidades();
    parametrosObrigatorios();
    getVacanciesInfo();
    getVerificationFieldsToFill();
  }, [
    getProfileInfo,
    loadUfs,
    getVacanciesInfo,
    parametrosObrigatorios,
    loadTodasCidades,
    getVerificationFieldsToFill,
  ]);

  useEffect(() => {
    if (currentUf) {
      loadCidades(currentUf);
    }
  }, [currentUf, loadCidades]);

  const appliedVacancies: Vacancy[] = useMemo(() => {
    const initialReduceState: VacancyStepCandidate[] = [];

    return vacancies.filter((vacancy) => {
      const candidates = vacancy.steps.reduce(
        (acc, next) => [
          ...acc,
          ...next.stepCandidates.map((candidate) => candidate),
        ],
        initialReduceState
      );

      const findCandidate = candidates.find(
        (candidate) => candidate.pes_id === user.pes_id
      );

      if (!findCandidate) return false;

      const excluido = !!Number(findCandidate.excluido);

      const contratado = !!Number(findCandidate.contratado);

      return findCandidate && !excluido && !contratado;
    });
  }, [user.pes_id, vacancies]);

  const permitirEditarPCD = useMemo(() => {
    /* Não importa o tipo de pcd da vaga, o que importa é se a vaga é do tipo pcd */
    let atLeastOneTrue = false;
    appliedVacancies.map((vacancy) => {
      vacancy.pcd === 1 ? (atLeastOneTrue = true) : "";
      return vacancy;
    });
    return atLeastOneTrue;
  }, [appliedVacancies]);

  const onFinishFailed = () => {
    let text = `Preencha os campos obrigatórios:`;
    const requiredFieldsList = [];

    // Infos Básicas
    if (!!checkNomeCompleto && !form.getFieldsValue().pes_nome) {
      requiredFieldsList.push(" nome");
    }
    if (!!checkEmail && !form.getFieldsValue().pes_email) {
      requiredFieldsList.push(" email");
    }
    if (!!checkCPF && !form.getFieldsValue().pes_cpf) {
      requiredFieldsList.push(" cpf");
    }
    if (!!checkGenero && !form.getFieldsValue().pes_sexo) {
      requiredFieldsList.push(" sexo");
    }
    if (!!checkDataNascimento && !form.getFieldsValue().pes_datanascimento) {
      requiredFieldsList.push(" data de nascimento");
    }
    if (!!checkCelular && !form.getFieldsValue().pes_cel) {
      requiredFieldsList.push(" celular");
    }
    // Endereço
    if ((!!checkEnderecoCompleto || !!checkCEP) && !form.getFieldsValue().cep) {
      requiredFieldsList.push(" cep");
    }
    if (
      (!!checkEnderecoCompleto || !!checkLogradouro) &&
      !form.getFieldsValue().logradouro
    ) {
      requiredFieldsList.push(" logradouro");
    }
    if (
      (!!checkEnderecoCompleto || !!checkBairro) &&
      !form.getFieldsValue().bairro
    ) {
      requiredFieldsList.push(" bairro");
    }
    if (
      (!!checkEnderecoCompleto || !!checkNumero) &&
      !form.getFieldsValue().nro
    ) {
      requiredFieldsList.push(" número");
    }
    if (
      (!!checkEnderecoCompleto || !!checkComplemento) &&
      !form.getFieldsValue().complemento
    ) {
      requiredFieldsList.push(" complemento");
    }
    if (
      (!!checkEnderecoCompleto || !!checkEstado) &&
      !form.getFieldsValue().ufid
    ) {
      requiredFieldsList.push(" estado");
    }
    if (
      (!!checkEnderecoCompleto || !!checkCidade) &&
      !form.getFieldsValue().cidadeid
    ) {
      requiredFieldsList.push(" cidade");
    }
    // Infos Profissionais
    if (!!checkProfissao && !form.getFieldsValue().profissao) {
      requiredFieldsList.push(" profissão");
    }
    if (!!checkPretensaoSalarial && !form.getFieldsValue().prtsalario) {
      requiredFieldsList.push(" pretensão salarial");
    }
    if (!!checkLinkedin && !form.getFieldsValue().linkedin) {
      requiredFieldsList.push(" Linkedin");
    }
    if (!!checkTwitter && !form.getFieldsValue().twitter) {
      requiredFieldsList.push(" Twitter");
    }
    if (!!checkPortfolio && !form.getFieldsValue().site) {
      requiredFieldsList.push(" site");
    }
    if (!!checkIndicacao && !form.getFieldsValue().indicacao) {
      requiredFieldsList.push(" indicação");
    }
    if (!!checkVideo && !form.getFieldsValue().video) {
      requiredFieldsList.push(" vídeo");
    }

    text = `${text} ${requiredFieldsList.toString()}.`;
    toast.error(text);
  };

  const handleUpdateProfile = useCallback(
    async (data: IProfile) => {
      setUpdatingProfile(true);
      let pcdValue = 0;
      if (checkPCD) {
        pcdValue = 1;
      } else {
        /* Remover laudo caso a opção de PCD esteja desmarcada */
        await api.delete(`/${user.account.conta_url}/profile/pcdlaudo`);
      }

      if (checkPCD && !isPCDFile && !permitirEditarPCD) {
        toast.error("É necessário fazer o Upload do Laudo de PCD!");
      } else if (checkCurriculo && curriculoUploaded === false) {
        toast.error("É necessário fazer o Upload do Currículo!");
      } else {
        try {
          const response = await api.put<IProfile>(
            `${user.account.conta_url}/profile`,
            {
              ...data,
              pcd: pcdValue,
            }
          );

          if (response.status === 200) {
            updateUser(response.data);
            setProfileInfo(response.data);
            if (location && location.state) {
              toast.success(
                "Preencha as pesquisas a seguir para se candidatar."
              );
            } else {
              toast.success(
                "Perfil atualizado com sucesso, redirecionando para a Dashboard em 5 segundos."
              );
            }
            setTimeout(() => {
              history.push(`/${user.account.conta_url}/dashboard`);
            }, 5000);
          }
        } finally {
          setUpdatingProfile(false);
        }
      }

      setUpdatingProfile(false);
    },
    [
      checkPCD,
      isPCDFile,
      permitirEditarPCD,
      checkCurriculo,
      curriculoUploaded,
      user.account.conta_url,
      updateUser,
      history,
    ]
  );

  const handleChangeChecked = () => {
    setPessoaComDeficiencia(!pessoaComDeficiencia);
    setCheckPCD(!checkPCD);
  };

  useEffect(() => {
    if (profileInfo) {
      const dateParsed = moment(profileInfo.pes_datanascimento);

      form.setFieldsValue({
        ...profileInfo,
        pes_sexo: profileInfo.pes_sexo.toUpperCase(),
        pes_datanascimento: dateParsed.isValid() ? dateParsed : undefined,
      });
    }
  }, [form, profileInfo, todasCidades, ufs]);

  const escolaridadeOptions = useMemo(
    () => [
      { label: "Ensino Fundamental Incompleto", value: "ens_fund_inc" },
      { label: "Ensino Fundamental Completo", value: "ens_fund_com" },
      { label: "Ensino Médio Incompleto", value: "ens_medio_inc" },
      { label: "Ensino Médio Completo", value: "ens_medio_com" },
      { label: "Ensino Superior Incompleto", value: "ens_sup_inc" },
      { label: "Ensino Superior Completo", value: "ens_sup_com" },
    ],
    []
  );

  const genderOptions = useMemo(
    () => [
      { label: "Masculino", value: "M" },
      { label: "Feminino", value: "F" },
      { label: "Outro", value: "OU" },
    ],
    []
  );

  const showModalPasswordClose = () => {
    setModalPasswordOpen(false);
  };

  const showModalPasswordCancel = () => {
    setModalPasswordOpen(false);
  };

  const showModalPasswordOpen = () => {
    setModalPasswordOpen(true);
  };

  const ufsSorted = useMemo(() => {
    return ufs.sort((a, b) => sorterString(a, b, "UF"));
  }, [ufs]);

  const ufsOptions = useMemo(
    () => ufsSorted.map(({ UFID, UF }) => ({ label: UF, value: UFID })),
    [ufsSorted]
  );

  const cidadesOptions = useMemo(
    () =>
      cidades.map(({ CIDADEID, CIDADE }) => ({
        label: CIDADE,
        value: CIDADEID,
      })),
    [cidades]
  );

  const todasCidadesOptions = useMemo(
    () =>
      todasCidades.map(({ CIDADEID, CIDADE }) => ({
        label: CIDADE,
        value: CIDADEID,
      })),
    [todasCidades]
  );

  const handleDeleteUserAccount = useCallback(async () => {
    const { account } = user;

    await api.delete(`/${account.conta_url}/profile`);

    history.push(`/${account.conta_url}`);

    signOut();

    toast.success("Account successfully deleted");
  }, [history, signOut, user]);

  const handleUploadCurriculum = useCallback(
    async ({
      file,
      onSuccess,
      onError,
      onProgress,
    }: Omit<IHandleUpload, "name">) => {
      setUploadingCurriculum(true);

      const formData = new FormData();

      formData.append("curriculum", file);

      try {
        const response = await api.patch(
          `/${user.account.conta_url}/profile/curriculum`,
          formData,
          {
            onUploadProgress: ({ total, loaded }) => {
              onProgress(
                { percent: Math.round((loaded / total) * 100).toFixed(2) },
                file
              );
            },
          }
        );

        getProfileUpload();

        onSuccess(file, response);
        setCurriculoUploaded(true);
      } catch (err) {
        onError(err);
      } finally {
        setUploadingCurriculum(false);
      }
    },
    [getProfileUpload, user.account.conta_url]
  );

  const handleUploadPCDLaudo = useCallback(
    async ({
      file,
      onSuccess,
      onError,
      onProgress,
    }: Omit<IHandleUpload, "name">) => {
      setUploadingPCDLaudo(true);

      const formData = new FormData();

      formData.append("pcdlaudo", file);

      try {
        const response = await api.put<IProfile>(
          `${user.account.conta_url}/profile`,
          {
            pcd: 1,
          }
        );

        if (response.status === 200) {
          // updateUser(response.data);
          // setProfileInfo(response.data);
        }
      } finally {
        setUpdatingProfile(false);
      }

      try {
        const response = await api.patch(
          `/${user.account.conta_url}/profile/pcdlaudo`,
          formData,
          {
            onUploadProgress: ({ total, loaded }) => {
              onProgress(
                { percent: Math.round((loaded / total) * 100).toFixed(2) },
                file
              );
            },
          }
        );

        getProfileUpload();

        onSuccess(file, response);
      } catch (err) {
        onError(err);
      } finally {
        setUploadingPCDLaudo(false);
        setIsPCDFile(true);
      }
    },
    [user.account.conta_url, getProfileUpload]
  );

  const handleDeleteCurriculum = useCallback(async () => {
    await api.delete(`/${user.account.conta_url}/profile/curriculum`);

    getProfileUpload();
  }, [getProfileUpload, user.account.conta_url]);

  const handleDeletePCDLaudo = useCallback(async () => {
    await api.delete(`/${user.account.conta_url}/profile/pcdlaudo`);

    getProfileUpload();
    setIsPCDFile(false);
  }, [getProfileUpload, user.account.conta_url]);

  const curriculumFileList: UploadFile[] = useMemo(() => {
    if (!profileInfo || !currentCurriculum) {
      return [];
    }

    return [
      {
        uid: "1",
        name: `CV - ${profileInfo.pes_nome}`,
        status: "done",
        size: 0,
        type: "pdf",
        url: currentCurriculum,
      },
    ];
  }, [currentCurriculum, profileInfo]);

  const PCDLaudoFileList: UploadFile[] = useMemo(() => {
    if (!profileInfo || !currentLaudo) {
      return [];
    }

    return [
      {
        uid: "1",
        name: `PCD-LAUDO - ${profileInfo.pes_nome}`,
        status: "done",
        size: 0,
        type: "pdf",
        url: currentLaudo,
      },
    ];
  }, [currentLaudo, profileInfo]);

  return (
    <>
      <Container>
        <Col xs={24} sm={24} md={16} lg={12}>
          <GoBack onClick={() => history.goBack()}>Voltar</GoBack>

          {fieldsToFill && (
            <Alert
              style={{ marginBottom: "12px" }}
              message="
    Sua ficha está incompleta. Por favor, preencha os campos
    obrigatórios."
              type="warning"
            />
          )}

          {profileInfo && (
            <Card style={{ marginBottom: "12px" }}>
              <Title level={3}>{profileInfo.pes_nome}</Title>
              <Text type="secondary">{profileInfo.pes_email}</Text>
            </Card>
          )}

          <Form
            form={form}
            layout="vertical"
            id="profile-form"
            onFinish={handleUpdateProfile}
            onFinishFailed={onFinishFailed}
            scrollToFirstError
          >
            <Card title="Informações básicas">
              <Form.Item
                name="pes_nome"
                label="Nome completo"
                rules={[
                  {
                    pattern: new RegExp("^[a-zA-Z \\b]+$"),
                    required: checkNomeCompleto,
                    message: "O nome é obrigatório!",
                  },
                ]}
              >
                <Input type="text" size="large" />
              </Form.Item>

              <Form.Item
                name="pes_email"
                label="E-mail"
                rules={[
                  {
                    type: "email",
                    required: checkEmail,
                    message: "O e-mail é obrigatório!",
                  },
                ]}
              >
                <Input type="text" size="large" />
              </Form.Item>

              <FormFlexItem width={100}>
                <Form.Item name="escolaridade" label="Escolaridade">
                  <Select options={escolaridadeOptions} size="large" />
                </Form.Item>
              </FormFlexItem>

              <FormFlexRow>
                <FormFlexItem width={60}>
                  <Form.Item
                    name="pes_cpf"
                    label="CPF"
                    rules={[
                      {
                        pattern: new RegExp("^[0-9*#._+(){}=:/,'&!@%-]+$"),
                        required: checkCPF,
                        message: "O CPF é obrigatório!",
                      },
                    ]}
                  >
                    <Input type="text" size="large" />
                  </Form.Item>
                </FormFlexItem>
                <FormFlexItem width={40}>
                  <Form.Item
                    name="pes_sexo"
                    label="Gênero"
                    rules={[
                      {
                        required: checkGenero,
                        message: "O gênero é obrigatório!",
                      },
                    ]}
                  >
                    <Select options={genderOptions} size="large" />
                  </Form.Item>
                </FormFlexItem>
              </FormFlexRow>

              <FormFlexRow>
                <FormFlexItem width={40}>
                  <Form.Item
                    name="pes_datanascimento"
                    label="Data de nascimento"
                    rules={[
                      {
                        required: checkDataNascimento,
                        message: "A data de nascimento é obrigatória!",
                      },
                    ]}
                  >
                    <DatePicker
                      size="large"
                      format="DD/MM/YYYY"
                      disabledDate={(current) => {
                        return moment() <= current;
                      }}
                    />
                  </Form.Item>
                </FormFlexItem>
                <FormFlexItem width={60}>
                  <Form.Item
                    name="pes_cel"
                    label="Celular"
                    rules={[
                      {
                        pattern: new RegExp("^[0-9*#._+(){}=:/,'&!@%-]+$"),
                        required: checkCelular,
                        message: "O celular é obrigatório!",
                      },
                    ]}
                  >
                    <Input type="text" size="large" />
                  </Form.Item>
                </FormFlexItem>
              </FormFlexRow>
            </Card>

            <Card title="Endereço">
              <FormFlexRow>
                <FormFlexItem width={30}>
                  <Form.Item
                    name="cep"
                    label="CEP"
                    rules={[
                      {
                        pattern: new RegExp("^[0-9*#._+(){}=:/,'&!@%-]+$"),
                        required: checkEnderecoCompleto || checkCEP,
                        message: "O CEP é obrigatório!",
                      },
                    ]}
                  >
                    <Input type="text" size="large" />
                  </Form.Item>
                </FormFlexItem>
                <FormFlexItem width={70}>
                  <Form.Item
                    name="logradouro"
                    label="Logradouro"
                    rules={[
                      {
                        required: checkEnderecoCompleto || checkLogradouro,
                        message: "O logradouro é obrigatório!",
                      },
                    ]}
                  >
                    <Input type="text" size="large" />
                  </Form.Item>
                </FormFlexItem>
              </FormFlexRow>
              <FormFlexRow>
                <FormFlexItem width={80}>
                  <Form.Item
                    name="bairro"
                    label="Bairro"
                    rules={[
                      {
                        required: checkEnderecoCompleto || checkBairro,
                        message: "O bairro é obrigatório!",
                      },
                    ]}
                  >
                    <Input type="text" size="large" />
                  </Form.Item>
                </FormFlexItem>
                <FormFlexItem width={20}>
                  <Form.Item
                    name="nro"
                    label="Número"
                    rules={[
                      {
                        pattern: new RegExp("^[0-9*#._+(){}=:/,'&!@%-]+$"),
                        required: checkEnderecoCompleto || checkNumero,
                        message: "O número é obrigatório!",
                      },
                    ]}
                  >
                    <Input type="text" size="large" />
                  </Form.Item>
                </FormFlexItem>
              </FormFlexRow>
              <Form.Item
                name="complemento"
                label="Complemento"
                rules={[
                  {
                    required: checkEnderecoCompleto || checkComplemento,
                    message: "O complemento é obrigatório!",
                  },
                ]}
              >
                <Input type="text" size="large" />
              </Form.Item>
              <FormFlexRow>
                <FormFlexItem width={40}>
                  <Form.Item
                    name="ufid"
                    label="Estado"
                    rules={[
                      {
                        required: checkEnderecoCompleto || checkEstado,
                        message: "O estado é obrigatório!",
                      },
                    ]}
                  >
                    <Select
                      options={ufsOptions.sort(
                        (a, b) => parseFloat(a.label) - parseFloat(b.label)
                      )}
                      size="large"
                      loading={loadingUfs}
                      onChange={(value) => {
                        setCurrentUf(Number(value));
                        form.setFieldsValue({
                          cidadeid: undefined,
                        });
                      }}
                    />
                  </Form.Item>
                </FormFlexItem>
                <FormFlexItem width={60}>
                  <Form.Item
                    name="cidadeid"
                    label="Cidade"
                    rules={[
                      {
                        required: checkEnderecoCompleto || checkCidade,
                        message:
                          "Após selecionar o estado, selecione a cidade!",
                      },
                    ]}
                  >
                    <Select
                      disabled={!currentUf}
                      options={
                        cidadesOptions.length > 0
                          ? cidadesOptions.sort(
                              (a, b) =>
                                parseFloat(a.label) - parseFloat(b.label)
                            )
                          : todasCidadesOptions
                      }
                      size="large"
                      loading={loadingCidades}
                    />
                  </Form.Item>
                </FormFlexItem>
              </FormFlexRow>
            </Card>

            <Card title="Informações Profissionais">
              <FormFlexRow>
                <FormFlexItem width={60}>
                  <Form.Item
                    name="profissao"
                    label="Profissão"
                    rules={[
                      {
                        required: checkProfissao,
                        message: "A profissão é obrigatório!",
                      },
                    ]}
                  >
                    <Input type="text" size="large" />
                  </Form.Item>
                </FormFlexItem>
                <FormFlexItem width={40}>
                  <Form.Item
                    name="prtsalario"
                    label="Pretensão Salarial"
                    rules={[
                      {
                        pattern: new RegExp("^[0-9*#._+(){}=:/,'&!@%-]+$"),
                        required: checkPretensaoSalarial,
                        message: "Pretensão salarial é obrigatório!",
                      },
                    ]}
                  >
                    <Input type="text" size="large" />
                  </Form.Item>
                </FormFlexItem>
              </FormFlexRow>

              <Form.Item
                name="linkedin"
                label="Linkedin"
                rules={[
                  {
                    required: checkLinkedin,
                    message: "Linkedin é obrigatório!",
                  },
                ]}
              >
                <Input prefix={<LinkedinOutlined />} type="text" size="large" />
              </Form.Item>

              <Form.Item
                name="twitter"
                label="Twitter"
                rules={[
                  {
                    required: checkTwitter,
                    message: "Twitter é obrigatório!",
                  },
                ]}
              >
                <Input prefix={<TwitterOutlined />} type="text" size="large" />
              </Form.Item>

              <Form.Item
                name="site"
                label="Site / Portifólio"
                rules={[
                  {
                    pattern: new RegExp("(?=.*[.]+[a-z])"),
                    required: checkPortfolio,
                    message: "Site/Portfólio é obrigatório!",
                  },
                ]}
              >
                <Input prefix={<LinkOutlined />} type="text" size="large" />
              </Form.Item>

              <Form.Item
                name="indicacao"
                label="Indicação"
                rules={[
                  {
                    required: checkIndicacao,
                    message: "Indicação é obrigatório!",
                  },
                ]}
              >
                <Input type="text" size="large" />
              </Form.Item>

              <Form.Item
                name="video"
                label="Link para um vídeo seu de apresentação no YouTube"
                rules={[
                  {
                    pattern: new RegExp("(?=.*[.]+[a-z])"),
                    required: checkVideo,
                    message: "Vídeo válido é obrigatório!",
                  },
                ]}
              >
                <Input
                  prefix={<YoutubeOutlined />}
                  placeholder="Exemplo: https://www.youtube.com/watch?v=H8URDijYx6s"
                  type="text"
                  size="large"
                />
              </Form.Item>

              <span>Currículo</span>
              <Upload accept=".pdf,application/pdf" multiple={false} />

              <Upload
                fileList={curriculumFileList}
                accept=".pdf,application/pdf"
                multiple={false}
                customRequest={({ file, onSuccess, onError, onProgress }) => {
                  handleUploadCurriculum({
                    file,
                    onSuccess,
                    onError,
                    onProgress,
                  });
                }}
                onRemove={() => handleDeleteCurriculum()}
              >
                <Button
                  loading={uploadingCurriculum}
                  disabled={uploadingCurriculum}
                  icon={<UploadOutlined />}
                  style={{
                    width: 156,
                  }}
                >
                  Enviar currículo
                </Button>
              </Upload>
              <Text type="secondary">Formato aceito: .pdf</Text>
              <br />
              <br />

              {permitirEditarPCD ? (
                <p
                  style={{
                    color: "red",
                  }}
                >
                  No momento você está participando de processos seletivos para
                  PCD, portanto você não pode editar a informação de PCD.
                </p>
              ) : (
                ""
              )}
              <Form.Item name="pcd" valuePropName="checked">
                <Checkbox
                  checked={pessoaComDeficiencia}
                  onChange={handleChangeChecked}
                  disabled={permitirEditarPCD}
                >
                  É pessoa com deficiência (PCD)?
                </Checkbox>
              </Form.Item>
              {pessoaComDeficiencia && (
                <>
                  <FormFlexRow>
                    <FormFlexItem width={50}>
                      <Form.Item label="Tipo de PCD" name="pcd_tipo">
                        <Select
                          placeholder="Selecione o tipo"
                          size="large"
                          disabled={permitirEditarPCD}
                        >
                          <Option value="Física">Física</Option>
                          <Option value="Auditiva">Auditiva</Option>
                          <Option value="Visual">Visual</Option>
                          <Option value="Mental">Mental</Option>
                          <Option value="Múltipla">Múltipla</Option>
                        </Select>
                      </Form.Item>
                    </FormFlexItem>
                    <FormFlexItem width={50}>
                      <Form.Item label="CID" name="cid">
                        <Input
                          type="text"
                          placeholder="Digite o CID"
                          size="large"
                          disabled={permitirEditarPCD}
                        />
                      </Form.Item>
                    </FormFlexItem>
                  </FormFlexRow>
                  <span>Laudo PCD</span>
                  <Upload accept=".pdf,application/pdf" multiple={false} />

                  <Upload
                    fileList={PCDLaudoFileList}
                    accept=".pdf,application/pdf"
                    multiple={false}
                    customRequest={({
                      file,
                      onSuccess,
                      onError,
                      onProgress,
                    }) => {
                      handleUploadPCDLaudo({
                        file,
                        onSuccess,
                        onError,
                        onProgress,
                      });
                    }}
                    onRemove={() => handleDeletePCDLaudo()}
                  >
                    <Button
                      loading={uploadingPCDLaudo}
                      disabled={permitirEditarPCD}
                      icon={<UploadOutlined />}
                      style={{
                        width: 156,
                      }}
                    >
                      Enviar Laudo PCD
                    </Button>
                  </Upload>
                  <Text type="secondary">Formato aceito: .pdf</Text>
                </>
              )}

              <ButtonContainer>
                <Button
                  type="primary"
                  size="large"
                  htmlType="submit"
                  form="profile-form"
                  loading={updatingProfile}
                  disabled={updatingProfile}
                >
                  Salvar alterações
                </Button>
              </ButtonContainer>
            </Card>
            <Card title="Excluir Conta">
              <Popconfirm
                title="Deseja excluir sua conta ?"
                onConfirm={handleDeleteUserAccount}
              >
                <Button type="ghost" danger size="large">
                  Excluir conta
                </Button>
              </Popconfirm>
            </Card>
          </Form>
          <Modal
            title="ALTERAR SENHA"
            visible={modalPasswordOpen}
            cancelText="CANCELAR"
            okText="SALVAR"
            onOk={showModalPasswordClose}
            onCancel={showModalPasswordCancel}
            maskClosable={false}
            width="60%"
            destroyOnClose
            okButtonProps={{
              form: "profile-form",
              htmlType: "submit",
            }}
            centered
          >
            <FormFlexRow>
              <FormFlexItem width={50}>
                <Form.Item name="old_password" label="Senha atual">
                  <Input
                    type="password"
                    placeholder="Digite sua senha atual"
                    size="large"
                  />
                </Form.Item>
              </FormFlexItem>

              <FormFlexItem width={50}>
                <Form.Item name="password" label="Nova senha">
                  <Input
                    type="password"
                    placeholder="Digite a nova senha"
                    size="large"
                  />
                </Form.Item>
              </FormFlexItem>
            </FormFlexRow>
          </Modal>
        </Col>
      </Container>
    </>
  );
};

export default Profile;
