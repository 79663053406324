import React from "react";

import { Spin } from "antd";

import { LoadingOutlined } from "@ant-design/icons";

import propTypes from "prop-types";

import { Container } from "./styles";

type LoadingProps = {
  size?: number;
  coverScreen?: boolean;
};

const Loading: React.FC<LoadingProps> = ({
  size = 128,
  coverScreen = true,
}) => {
  return (
    <Container coverScreen={coverScreen}>
      <Spin indicator={<LoadingOutlined spin style={{ fontSize: size }} />} />
    </Container>
  );
};

Loading.defaultProps = {
  size: 128,
  coverScreen: true,
};

export default Loading;
