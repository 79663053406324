import styled, { keyframes } from "styled-components";

import { shade } from "polished";
import { Modal } from "antd";

import signInBackgroundImg from "../../assets/default_background.jpg";

export const Container = styled.div`
  height: 100vh;
  display: flex;
  align-items: stretch;
  width: 100%;
  min-height: 640px;
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  width: 100%;
  max-width: 700px;
  padding: 0 20px;
  height: 100%;
  min-height: 640px;
`;

const appearFromLeft = keyframes`
  from {
    opacity: 0;
    transform: translateX(-50px);
  }
  to {
    opacity: 1;
    transform: translateX(0);
  }
`;

export const AnimationContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  max-width: 100%;

  height: 100%;
  flex-direction: column;
  margin-top: auto;
  margin-bottom: auto;

  animation: ${appearFromLeft} 1s;

  > img {
    min-height: 70px;
    max-width: 90%;
    max-height: 20%;
  }

  form {
    width: 100%;
    margin: 30px 0px 10px;
    max-width: 340px;
    align-items: center;
    text-align: center;

    > .ant-form-item {
      max-width: 100%;

      .ant-input-prefix {
        margin-right: 11px;
      }
    }

    > button {
      width: 100%;
    }

    h1 {
      margin-bottom: 24px;
    }

    a {
      color: #f4ede8;
      display: block;
      margin-top: 24px;
      text-decoration: none;
      transition: color 0.2s;

      &:hover {
        color: ${shade(0.2, "#f4ede8")};
      }
    }
  }

  > a {
    color: var(--primary);
    margin-top: 24px;
    text-decoration: none;
    transition: color 0.2s;
    display: flex;
    align-items: center;

    &:hover {
      color: ${(props) => shade(0.2, props.theme.primary)};
    }

    svg {
      margin-right: 16px;
    }
  }
`;

interface BackgroundProps {
  background: any;
}

export const Background = styled.div<BackgroundProps>`
  flex: 1;
  background: url(${(props) => props.background}) no-repeat center;
  background-size: cover;
  flex-direction: column;
  margin: 0;
  margin-bottom: 0;
`;

export const WrapperTermo = styled.div`
  display: flex;
  flex-direction: row;
  text-align: left;
  font-size: 15px;
  margin-bottom: -15px;

  button {
    background: transparent;
    border: none;
    font-size: 15px;
    color: blue;
    text-decoration: underline;
  }

  p {
    margin-left: 24px;
  }
`;

export const ModalStyled = styled(Modal)`
  .ant-modal-content {
    height: 100%;
    display: flex;
    flex-direction: column;
  }
`;

export const TextoTermoDescription = styled.div`
  display: flex;
  flex-direction: column;
  align-items: stretch;
  min-height: 0;
  background-color: var(--black-lighter);

  margin-top: 8px;

  p {
    color: var(--light-gray2);
    font-size: 18px;
  }

  strong,
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    font-weight: 500;
    color: var(--primary-shaded);
  }

  strong {
    font-size: 20px;
  }

  h1 {
    font-size: 26px;
  }

  h2 {
    font-size: 24px;
  }

  h3 {
    font-size: 24px;
  }

  h4 {
    font-size: 22px;
  }

  h5 {
    font-size: 22px;
  }

  h6 {
    font-size: 20px;
  }

  li {
    margin-left: 20px;
    color: unset;
  }
`;
