import React, { useCallback, useEffect, useMemo, useState } from "react";

import { Input, Button, Form, message, Alert, Checkbox, Modal } from "antd";

import { Link, useHistory, useLocation, useParams } from "react-router-dom";

import { UserOutlined, LockOutlined, MailOutlined } from "@ant-design/icons";
import { FiArrowLeft } from "react-icons/fi";

import { toast } from "react-toastify";

import DOMPurify from "dompurify";

import moment from "moment";

import { RuleObject } from "antd/lib/form";
import { stringify } from "querystring";
import logoImg from "../../assets/logo.png";
import defaultBackground from "../../assets/default_background.jpg";

import {
  Container,
  Content,
  Background,
  AnimationContainer,
  WrapperTermo,
  ModalStyled,
  TextoTermoDescription,
} from "./styles";
import api from "../../services/api";
import apiGc from "../../services/apiGc";
import { useAuth } from "../../hooks/auth";
import { useTheme } from "../../hooks/theme";

interface SignUpFormData {
  name: string;
  email: string;
  password: string;
}

const SignUp: React.FC = () => {
  const { params } = useTheme();

  const history = useHistory();

  const location = useLocation<{ vacancy_id?: number }>();

  const { account } = useParams<{ account: string }>();

  const [checkTermo, setCheckTermo] = useState(false);

  const [termoTexto, setTermoTexto] = useState("");

  const [termosCondicoes, setTermosCondicoes] = useState(false);

  const { signIn } = useAuth();

  const handleSubmit = useCallback(
    async (data: SignUpFormData) => {
      try {
        await api.post(`/${account}/candidates`, {
          ...data,
          termo_aceito: checkTermo ? 1 : 0,
        });

        await signIn({
          account,
          email: data.email,
          password: data.password,
        });

        if (location && location.state) {
          if (location.state.vacancy_id) {
            history.push(`/${account}/profile`, {
              state: location.state.vacancy_id,
            });
            return;
          }
        }
        history.push(`/${account}/profile`);
      } catch (err: any) {
        if (err.message === "Request failed with status code 400") {
          message.error(
            "O email inserido já está cadastrado, por favor, faça login",
            7
          );
        }
        toast.error("Ocorreu um erro criar sua conta, tente novamente.");
      }
    },
    [account, history, location, signIn, checkTermo]
  );

  const loadTermo = useCallback(async () => {
    try {
      const response = await apiGc.get(`/termo-e-condicoes/conta/${account}`);

      if (response.status === 200) {
        const { ativo } = response.data.termo;

        if (ativo) {
          const { termo } = response.data.termo;
          setTermoTexto(termo);
        }
      }
    } catch (err) {
      toast.error("Não foi possível encontrar o termo para essa conta!");
    }
  }, [account]);

  useEffect(() => {
    loadTermo();
  }, [loadTermo]);

  const handleCheckboxTermo = (e: any) => {
    setCheckTermo(e.target.checked);
  };

  const validationCheckBox = (
    rule: RuleObject,
    value: any,
    callback: (error?: string) => void
  ) => {
    if (checkTermo) {
      return callback();
    }
    return callback("Por favor, aceite os termos e condições.");
  };

  const logo = useMemo(() => {
    return params && params.logo ? params.logo : logoImg;
  }, [params]);

  const background = useMemo(() => {
    return params && params.background_img
      ? params.background_img
      : defaultBackground;
  }, [params]);

  const handleAcceptedTermo = () => {
    setCheckTermo(true);
    setTermosCondicoes(false);
  };

  const purifiedTermoTexto = useMemo(() => {
    if (!termoTexto) {
      return null;
    }

    return DOMPurify.sanitize(termoTexto, {
      ALLOWED_TAGS: [
        "h1",
        "h2",
        "h3",
        "h4",
        "h5",
        "h6",
        "p",
        "span",
        "strong",
        "em",
        "ul",
        "li",
        "ol",
      ],
    });
  }, [termoTexto]);

  return (
    <Container>
      <Content>
        <AnimationContainer>
          <Link to={{ pathname: `/${account}/` }}>
            {" "}
            <img style={{ maxHeight: 120 }} src={logo} alt="TeamHub" />
          </Link>
          <Form onFinish={handleSubmit} layout="vertical">
            <h1>Crie sua conta</h1>
            <Form.Item>
              <Alert
                message="Se você ainda não tem cadastro em nenhum produto da TeamHub, crie sua conta abaixo."
                type="info"
              />
            </Form.Item>
            <Form.Item
              name="name"
              rules={[
                {
                  pattern: new RegExp("^[a-zA-Z \\b]+$"),
                  required: true,
                  message: "Por favor, preencha nome completo",
                },
              ]}
            >
              <Input
                prefix={<UserOutlined />}
                type="text"
                placeholder="Nome completo"
                size="large"
              />
            </Form.Item>

            <Form.Item
              name="email"
              rules={[
                { required: true, message: "Por favor, preencha seu e-mail" },
              ]}
            >
              <Input
                prefix={<MailOutlined />}
                type="email"
                placeholder="E-mail"
                size="large"
              />
            </Form.Item>

            <Form.Item
              name="password"
              rules={[
                {
                  required: true,
                  message:
                    "Por favor, informe uma senha com no mínimo 8 caracteres",
                  min: 8,
                },
              ]}
            >
              <Input
                prefix={<LockOutlined />}
                type="password"
                placeholder="Senha"
                size="large"
              />
            </Form.Item>
            {termoTexto ? (
              <Form.Item
                name="termo_aceito"
                rules={[
                  {
                    validator: validationCheckBox,
                  },
                ]}
              >
                <WrapperTermo>
                  <Checkbox
                    style={{
                      textAlign: "justify",
                    }}
                    checked={checkTermo}
                    onChange={handleCheckboxTermo}
                  >
                    Estou de acordo com o &zwnj;
                    <button
                      type="button"
                      onClick={() => setTermosCondicoes(true)}
                    >
                      Termo de Autorização
                    </button>
                    <p>&zwnj; de compartilhamento dos dados.</p>
                  </Checkbox>
                </WrapperTermo>
              </Form.Item>
            ) : (
              ""
            )}

            <Button
              type="primary"
              ghost
              htmlType="submit"
              size="large"
              style={{
                marginBottom: "-25px",
              }}
            >
              Cadastrar
            </Button>
          </Form>

          <Link to={`/${account}/signin`}>
            <FiArrowLeft />
            Voltar para logon
          </Link>
        </AnimationContainer>
      </Content>
      <Background background={background} />
      <ModalStyled
        title="TERMOS E CONDIÇÕES"
        centered
        visible={termosCondicoes}
        onCancel={handleAcceptedTermo}
        cancelText="Aceito"
        closeIcon
        maskClosable={false}
        okText="Cancelar"
        onOk={() => setTermosCondicoes(false)}
        bodyStyle={{
          overflowY: "scroll",
        }}
        style={{
          height: "calc(100vh - 100px)",
          top: 30,
          textAlign: "center",
        }}
        width={600}
        okButtonProps={{
          type: "default",
        }}
        cancelButtonProps={{
          type: "primary",
        }}
      >
        <h4
          style={{
            textAlign: "justify",
          }}
        >
          {purifiedTermoTexto && (
            <>
              <TextoTermoDescription
                dangerouslySetInnerHTML={{ __html: purifiedTermoTexto }}
              />
            </>
          )}
        </h4>
      </ModalStyled>
    </Container>
  );
};

export default SignUp;
