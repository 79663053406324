import React from "react";

import moment from "moment";

export function sorterString(a, b, prop) {
  let nameA;
  let nameB;

  if (Array.isArray(prop)) {
    nameA = prop.reduce((acc, next) => acc[next], a);
    nameB = prop.reduce((acc, next) => acc[next], b);
  } else {
    nameA = a[prop] && `${a[prop]}`.toUpperCase();
    nameB = b[prop] && `${b[prop]}`.toUpperCase();
  }

  if (nameB === null) {
    return -1;
  }
  if (nameA === null) {
    return 1;
  }
  if (nameA < nameB) {
    return -1;
  }
  if (nameA > nameB) {
    return 1;
  }
  return 0;
}

export function sorterNumber(a, b, prop) {
  let valueA;
  let valueB;

  if (Array.isArray(prop)) {
    valueA = prop.reduce(
      (acc, next) => (acc && acc[next] ? acc[next] : null),
      a
    );
    valueB = prop.reduce(
      (acc, next) => (acc && acc[next] ? acc[next] : null),
      b
    );
  } else {
    valueA = a[prop] && a[prop].toUpperCase();
    valueB = b[prop] && b[prop].toUpperCase();
  }

  valueA = parseFloat(valueA);
  valueB = parseFloat(valueB);

  if (valueA && valueB) {
    return valueA - valueB;
  } else if (valueA) {
    return 1;
  } else if (valueB) {
    return -1;
  } else return 0;
}

export function sorterDate(a, b, prop, format = "") {
  const nameA = a[prop];
  const nameB = b[prop];
  if (!nameA) {
    return -1;
  }
  if (!nameB) {
    return 1;
  }
  if (format) {
    return moment(nameA, format).unix() - moment(nameB, format).unix();
  }
  return moment(nameA).unix() - moment(nameB).unix();
}
