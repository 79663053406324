import React, {
  createContext,
  useCallback,
  useState,
  useContext,
  useEffect,
} from "react";

import { darken, lighten, shade } from "polished";

import { useRouteMatch } from "react-router-dom";

import { ThemeProvider } from "styled-components";

import apiGc from "../services/apiGc";

interface Param {
  id: number;
  param: string;
  value: string;
  customParam?: {
    param_id: number;
    emp_id: number;
    conta_id: number;
    value: string;
    url?: string;
  };
}

interface ParamState {
  [key: string]: string;
}

export interface ThemeState {
  header: string;
  primary: string;
  secondary: string;
  primaryShaded: string;
  secondaryShaded: string;
  gray1: string;
  gray2: string;
  gray3: string;
  gray4: string;
  gray5: string;
  grayShaded: string;
  darkGray: string;
  "font.100": string;
  "font.200": string;
  "font.400": string;
  "font.600": string;
  "font.800": string;
  "background.400": string;
  "background.600": string;
  "background.800": string;
}

interface Image {
  id: number;
  emp_id: number;
  conta_id: number;
  filename: string;
  url: string;
}

interface ThemeContextData {
  params: ParamState;
  gallery: Image[];
  theme: ThemeState;
  loadingTheme: boolean;
}

interface IMatchParams {
  account: string;
}

const ThemeContext = createContext<ThemeContextData>({} as ThemeContextData);

const defaultTheme = {
  primary: "#1890ff",
  primaryShaded: shade("0.1", "black"),
  header: "#fff",
  secondary: "#30318b",
  secondaryShaded: shade("0.1", "#30318b"),
  gray1: darken("0.2", "#b1b1b3"),
  gray2: darken("0.15", "#b1b1b3"),
  gray3: darken("0.1", "#b1b1b3"),
  gray4: darken("0.05", "#b1b1b3"),
  gray5: "#b1b1b3",
  darkGray: "#121212",
  "font.100": "#3b3b3b",
  "font.200": "#2b2b2b",
  "font.400": "#242424",
  "font.600": "#1a1a1a",
  "font.800": "#0f0f0f",
  "background.400": "#ebebeb",
  "background.600": "#f2f2f2",
  "background.800": "#fff",
};

export const CustomThemeProvider: React.FC = ({ children }) => {
  const match = useRouteMatch<IMatchParams>({
    path: "/:account",
    exact: false,
  });

  const [account, setAccount] = useState<string>();
  const [params, setParams] = useState<ParamState>(() => {
    const savedParams = localStorage.getItem("@JobsTeamhub:params");

    if (!savedParams) {
      return {};
    }

    const parsedParams = JSON.parse(savedParams);

    if (!savedParams || savedParams === "undefined" || savedParams === "null") {
      return {};
    }

    return parsedParams;
  });
  const [gallery, setGallery] = useState<Image[]>([]);
  const [loadingTheme, setLoadingTheme] = useState(false);
  const [theme, setTheme] = useState<ThemeState>(() => {
    const savedTheme = localStorage.getItem("@JobsTeamhub:theme");

    if (!savedTheme) {
      return defaultTheme;
    }

    const parsedTheme = JSON.parse(savedTheme);

    if (!parsedTheme || parsedTheme === "undefined" || parsedTheme === "null") {
      return defaultTheme;
    }

    return { ...defaultTheme, ...parsedTheme };
  });

  useEffect(() => {
    if (match && match.params.account) {
      setAccount(match.params.account);
    }
  }, [match]);

  const loadParams = useCallback(async () => {
    setLoadingTheme(true);

    try {
      const response = await apiGc.get<Param[]>(
        `/trabalheConosco/params/conta/${account}`
      );

      const paramsWithUrl = ["logo", "background_img"];

      if (response.status === 200) {
        const parsedParams = response.data.reduce(
          (acc, next) => ({
            ...acc,
            [next.param]: next.customParam
              ? paramsWithUrl.includes(next.param)
                ? next.customParam.url
                : next.customParam.value
              : next.value,
          }),
          {}
        );

        setParams(parsedParams);

        localStorage.setItem(
          "@JobsTeamhub:params",
          JSON.stringify(parsedParams)
        );

        // const primaryColor = response.data.find(
        //   (param) => param.param === "primaryColor"
        // );

        // const secondaryColor = response.data.find(
        //   (param) => param.param === "secondaryColor"
        // );

        // const updatedTheme = {
        //   primary: primaryColor
        //     ? primaryColor.customParam
        //       ? primaryColor.customParam.value
        //       : primaryColor.value
        //     : "#ef5472",
        //   primaryShaded: primaryColor
        //     ? primaryColor.customParam
        //       ? shade("0.1", primaryColor.customParam.value)
        //       : shade("0.1", primaryColor.value)
        //     : shade("0.1", "#ef5472"),
        //   secondary: secondaryColor
        //     ? secondaryColor.customParam
        //       ? secondaryColor.customParam.value
        //       : secondaryColor.value
        //     : "#30318b",
        //   secondaryShaded: secondaryColor
        //     ? secondaryColor.customParam
        //       ? shade("0.1", secondaryColor.customParam.value)
        //       : shade("0.1", secondaryColor.value)
        //     : shade("0.1", "#30318b"),
        // };

        // setTheme((state) => ({ ...state, ...updatedTheme }));

        // localStorage.setItem(
        //   "@JobsTeamhub:theme",
        //   JSON.stringify(updatedTheme)
        // );
      }
    } finally {
      setLoadingTheme(false);
    }
  }, [account]);

  // useEffect(() => {
  //   (window as any).less.modifyVars({
  //     "@primary-color": theme.primary,
  //   });
  // }, [theme]);

  const loadGallery = useCallback(async () => {
    const response = await apiGc.get<Image[]>(
      `/trabalheConosco/gallery/conta/${account}`
    );

    if (response.status === 200) {
      setGallery(response.data);
    }
  }, [account]);

  useEffect(() => {
    if (account) {
      loadParams();
      loadGallery();
    }
  }, [account, loadGallery, loadParams]);

  return (
    <ThemeContext.Provider value={{ gallery, params, theme, loadingTheme }}>
      <ThemeProvider theme={theme}>{children}</ThemeProvider>
    </ThemeContext.Provider>
  );
};

export const useTheme = (): ThemeContextData => {
  const context = useContext(ThemeContext);

  return context;
};
