import React, { FC } from "react";

import moment from "moment";

import { Button } from "antd";

import { getContaUrl } from "services/auth";

import { Container, Wrapper } from "./style";

interface PesquisaProps {
  tipo: string;
  hash: string;
  data_entrega?: string | null;
  data_solicitacao: string;
}

const Pesquisa: FC<PesquisaProps> = ({
  tipo,
  hash,
  data_entrega,
  data_solicitacao,
}: PesquisaProps) => {
  const contaUrl = getContaUrl();

  const handleClickPesquisa = (
    tipoPesquisa: string,
    hashPesquisa: string
  ): void => {
    switch (tipoPesquisa.toUpperCase()) {
      case "PERFIL COMPORTAMENTAL":
        window.open(
          `${process.env.REACT_APP_MAIN_URL}${
            contaUrl || "apollo"
          }/pesquisas/perfil-comportamental/${hashPesquisa}`
        );
        break;
      case "PESQUISA DE PERCEPÇÃO":
        window.open(
          `${process.env.REACT_APP_MAIN_URL}${
            contaUrl || "apollo"
          }/pesquisas/percepcao-ambiente/${hashPesquisa}`
        );
        break;
      case "VALORES ORGANIZACIONAIS":
        window.open(
          `${process.env.REACT_APP_MAIN_URL}${
            contaUrl || "apollo"
          }/pesquisas/valores-organizacionais/${hashPesquisa}`
        );
        break;
      default:
        break;
    }
  };

  return (
    <Wrapper>
      <Container respondido={Boolean(data_entrega)} tipo={tipo}>
        <span className="tipo">{tipo}</span>
        <hr />
        <div className="data-container">
          <span className="label-data">Solicitada em </span>
          <span className="data">
            {moment(data_solicitacao).format("DD/MM/YYYY").toString()}
          </span>
        </div>
        {data_entrega && (
          <>
            <div className="data-container">
              <span className="label-data">Entregue em </span>
              <span className="data">
                {moment(data_entrega).format("DD/MM/YYYY").toString()}
              </span>
            </div>
          </>
        )}
        {!data_entrega && hash && (
          <Button
            type="primary"
            onClick={() => handleClickPesquisa(tipo, hash)}
          >
            Iniciar
          </Button>
        )}
      </Container>
    </Wrapper>
  );
};

Pesquisa.defaultProps = {
  data_entrega: null,
};

export default Pesquisa;
